<template>
  <div>
    <b-tooltip
      label="Opis statusów oraz kolorów terminarza"
      type="is-dark"
      position="is-bottom"
      :animated="false"
      :delay="500"
      class="mr-1"
    >
      <b-button size="is-small" @click="isComponentModalActive = true" icon-left="bullhorn">Legenda</b-button>
    </b-tooltip>
    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      scroll="keep"
      class="chat-pad chatsize"
    >
      <ContentPanel
        :rounded="true"
        title="Opis bieżących kolorów terminarza"
        icon="bullhorn"
        :marginless="true"
        :paddingless="true"
      >
        <template slot="addon">
          <b-button
            icon-left="close"
            size="is-small"
            @click="isComponentModalActive = false"
          >Zamknij</b-button>
        </template>
        <div class="modal-card" style="width:100% !important;min-height:50vh; max-height: 90vh;">
          <section class="modal-card-body">
            <div class="columns">
              <div class="column is-3">
                <table class="table is-narrow">
                  <tr>
                    <th colspan="2">Ikony płatnika</th>
                  </tr>
                  <tr>
                    <td style="width:1rem;">
                      <b-icon icon="cash-multiple"></b-icon>
                    </td>
                    <td>Komercja</td>
                  </tr>
                  <tr>
                    <td>
                      <b-icon icon="heart-half-full"></b-icon>
                    </td>
                    <td>NFZ</td>
                  </tr>
                  <tr>
                    <td>
                      <b-icon icon="home-city"></b-icon>
                    </td>
                    <td>MZ</td>
                  </tr>
                  <tr>
                    <th colspan="2" class="pt-5">Ikony EWUŚ</th>
                  </tr>
                  <tr>
                    <td>
                      <b-icon icon="shield-check"></b-icon>
                    </td>
                    <td>Ubezpieczony</td>
                  </tr>
                  <tr>
                    <td>
                      <b-icon icon="shield-alert"></b-icon>
                    </td>
                    <td>Brak ubezpieczenia</td>
                  </tr>
                  <tr>
                    <td>
                      <b-icon icon="shield-off"></b-icon>
                    </td>
                    <td>Inny status</td>
                  </tr>
                  <tr>
                    <td>brak</td>
                    <td>Brak informacji EWUŚ</td>
                  </tr>
                  <tr>
                    <th colspan="2" class="pt-5">Inne</th>
                  </tr>
                  <tr>
                    <td>
                      <b-icon icon="message-reply-text"></b-icon>
                    </td>
                    <td>Wizyta posiada skojarzone wiadomości SMS</td>
                  </tr>
                </table>
              </div>
              <div class="column is-3">
                <table class="table w-100 is-narrow">
                  <tr>
                    <th colspan="2">Ikony statusu na wizytach</th>
                  </tr>
                  <tr>
                    <td style="width: 10px;background: red;">
                      <b-icon icon="exclamation-thick" type="is-light" style="margin:2px 0 0 0;"></b-icon>
                    </td>
                    <td class="pl-2">Wizyta pilna</td>
                  </tr>
                  <tr>
                    <td style="width: 10px;background: orange;">
                      <b-icon icon="account" style="margin:2px 0 0 0;"></b-icon>
                    </td>
                    <td class="pl-2">Pacjent w poczekalni</td>
                  </tr>
                  <tr>
                    <td style="width: 10px;background: mediumpurple;">
                      <b-icon icon="calendar" style="margin:2px 0 0 0;"></b-icon>
                    </td>
                    <td class="pl-2">Rezerwacja</td>
                  </tr>
                  <tr>
                    <td style="width: 10px;background: greenyellow;">
                      <b-icon icon="refresh" type="is-dark" style="margin:2px 0 0 0;"></b-icon>
                    </td>
                    <td class="pl-2">Możliwe przełożenie</td>
                  </tr>
                  <tr>
                    <td style="width: 10px;background: DarkTurquoise;">
                      <b-icon icon="clock-alert" style="margin:2px 0 0 0;"></b-icon>
                    </td>
                    <td class="pl-2 pr-5">Wizyta w trakcie</td>
                  </tr>
                  <tr>
                    <td style="width: 10px;background: rgb(255, 213, 0);">
                      <b-icon icon="clipboard-account" type="is-danger" style="margin:2px 0 0 0;"></b-icon>
                    </td>
                    <td class="pl-2">Brak deklaracji</td>
                  </tr>
                  <tr>
                    <td style="width: 10px;background: #17A589;">
                      <b-icon icon="phone" type="is-light" style="margin:2px 0 0 0;"></b-icon>
                    </td>
                    <td class="pl-2 pr-5">Teleporada</td>
                  </tr>
                  <tr>
                    <td style="width: 10px;background: #884EA0;">
                      <b-icon icon="car" type="is-light" style="margin:2px 0 0 0;"></b-icon>
                    </td>
                    <td class="pl-2">Wizyta domowa</td>
                  </tr>
                </table>
              </div>
              <div class="column is-3">
                <table class="table w-100 is-narrow">
                  <tr>
                    <th colspan="2">Kolory aktualnych wizyt</th>
                  </tr>
                  <tr v-for="item in apps" :key="item.id">
                    <td :style="`width: 10px;background: ${item.color};`">
                      <b-icon :icon="item.icon" type="is-light" style="margin:2px 0 0 0;"></b-icon>
                    </td>
                    <td class="pl-2">{{item.name}}</td>
                  </tr>
                  <tr v-if="apps.length == 0">
                    <td colspan="2">Brak wizyt w terminarzu</td>
                  </tr>
                </table>
              </div>
              <div class="column is-3">
                <table class="table w-100 is-narrow">
                  <tr>
                    <th colspan="2">Kolory aktualnych stref</th>
                  </tr>
                  <tr v-for="item in areas" :key="item.id">
                    <td :style="`width: 10px;background: ${item.color};`">
                      <b-icon :icon="item.icon" type="is-light" style="margin:2px 0 0 0;"></b-icon>
                    </td>
                    <td class="pl-2">{{item.name}}</td>
                  </tr>
                  <tr v-if="areas.length == 0">
                    <td colspan="2">Brak stref w terminarzu</td>
                  </tr>
                </table>
              </div>
            </div>
          </section>
        </div>
      </ContentPanel>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'ScheduleDynoLegend',
  components: {},
  mixins: [],

  data: function () {
    return {
      /**
       * Modal active/inactive controller.
       */
      isComponentModalActive: false,
      apps: [],
      areas: [],
    }
  },

  props: {
    schedule: { required: true, type: Object },
  },

  watch: {
    isComponentModalActive(val) {
      if (val) {
        this.apps = []
        this.areas = []

        let a = []
        let w = []

        if (this.schedule && this.schedule.days) {
          this.schedule.days.forEach(day => {
            if (day.appointments) {
              day.appointments.forEach(app => {
                let existing = a.find(x => x.type == app.type && x.subtype == app.subtype)

                if (existing) {
                  // ok
                }
                else {
                  var config = this.confApps.find(a => a.id == app.type)
                  let col = "#FFF"
                  let nm = "?"
                  let ico = "calendar"

                  if (config) {
                    col = config.lightColor
                    nm = config.name
                    ico = config.icon

                    if (app.subtype > -1 && config.subcategories) {
                      var config2 = config.subcategories.find(a => a.id == app.subtype)

                      if (config2) {
                        col = config2.lightColor
                        nm = nm += ", " + config2.name
                        ico = config2.icon
                      }
                    }

                    a.push({ type: app.type, subtype: app.subtype, color: col, name: nm, icon: ico })
                  }
                }
              })
            }

            if (day.worktimes) {
              day.worktimes.forEach(ar => {
                let existing = w.find(x => x.area == ar.area)

                if (existing) {
                  // ok
                }
                else {
                  var config = this.confAreas.find(a => a.id == ar.area)
                  let col = "#FFF"
                  let nm = "?"

                  if (config) {
                    col = config.lightColor
                    nm = config.name
                  }

                  w.push({ area: ar.area, color: col, name: nm })
                }
              })
            }
          });
        }

        this.apps = a
        this.areas = w
      }
    }
  },

  computed: {
    confApps() {
      return this.$store.state.config.appointments;
    },
    confAreas() {
      return this.$store.state.config.areas;
    },
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style scoped lang="scss">
</style>
