<template>
  <div>
    <b-tooltip
      label="Mój terminarz"
      type="is-dark"
      position="is-bottom"
      :animated="false"
      :delay="500"
    >
      <b-button
        type="is-primelight"
        outlined
        size="is-small"
        @click="isComponentModalActive = true"
        icon-left="calendar-account"
      ></b-button>
    </b-tooltip>

    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      class="schedulesize"
      scroll="keep"
      :destroy-on-hide="false"
    >
      <ContentPanel title="Mój terminarz" icon="calendar" :paddingless="true" :marginless="true">
        <template slot="addon">
          <b-button
            @click="isComponentModalActive = false"
            icon-left="close"
            size="is-small"
          >Zamknij</b-button>
        </template>

        <div class="modal-card" style="width:100% !important;">
          <section class="modal-card-body">
            <MySchedulePanel></MySchedulePanel>
          </section>
        </div>
      </ContentPanel>
    </b-modal>
  </div>
</template>

<script>
// import ContentPanel from '@/components/tools/ContentPanel'
import MySchedulePanel from '@/components/schedule2/wrappers/MySchedulePanel'
import CommonMixins from '@/mixins/commons'

/**
 * SZOK Alerts modal and listener.
 * @author MC
 */
export default {
  name: 'MySchedule',
  components: {
  //  ContentPanel,
    MySchedulePanel
  },

  mixins: [CommonMixins],

  data: function () {
    return {

      /**
       * Modal active/inactive controller.
       */
      isComponentModalActive: false,

      /**
       * Connection loading indicator.
       */
      loading: false,

    }
  },

  computed: {
    /**
     * Current employee account.
     */
    me() { return this.$store.state.employee.me },

    /**
     * Current user permits.
     */
    permits() { return this.$store.state.employee.permits },

    /**
     * User token.
     */
    token() { return this.$store.state.identity.session ? this.$store.state.identity.session.token : '' },
  },

  mounted() {

  },

  watch: {

  },

  methods: {

  }
}
</script>

<style scoped lang="scss">
</style>
