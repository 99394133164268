<template>
  <div>
    <div
      class="modal-card"
      style="overflow: visible; width: 100% !important;max-height:88vh;min-height:80vh;"
    >
      <section
        class="modal-card-body pb-3"
        v-if="appointment && !isVehicle"
        style="min-height:60vh;"
      >
        <div class="columns">
          <div class="column is-6">
            <b-notification
              animation="none"
              type="is-warning"
              class="mb-1"
              v-if="block.appointment.isApkolce"
            >
              <b>Rejestrowana w AP-KOLCE</b>
            </b-notification>
            <b-notification
              animation="none"
              type="is-danger"
              class="mb-3"
              v-if="block.appointment.state == 1"
            >
              <b>Wizyta w trakcie</b>
            </b-notification>
            <b-notification
              animation="none"
              type="is-primary"
              class="mb-1"
              v-if="block.appointment.isGroup && !isHistory"
            >
              <b>Wizyta grupowa</b>
              <b-button
                size="is-small"
                v-if="registrationPatient && block.appointment.groupPatients.includes(registrationPatient.id)"
                class="is-pulled-right"
                disabled
                icon-left="check"
                rounded
              >Pacjent zapisany</b-button>
              <b-button
                size="is-small"
                @click="addToGroup"
                v-else-if="registrationPatient"
                class="is-pulled-right"
                :loading="loading"
                icon-left="account-plus"
                rounded
              >Dopisz pacjenta do wizyty</b-button>
            </b-notification>
            <b-notification
              type="is-kin"
              animation="none"
              class="mb-1"
              v-if="block.appointment.state == 8"
            >
              <b>Wizyta nie odbyła się.</b> Termin wizyty minął.
              <b-button
                class="button is-pulled-right"
                size="is-small"
                type="is-primary"
                rounded
                icon-left="swap-horizontal-bold"
                @click="reschedule"
                :loading="requestInProgress"
                :disabled="readonly ||
                ((block.appointment.state < 100 && block.appointment.state > 4) && block.appointment.state != 8) ||
                (block.appointment.state > 0 && isHistory && block.appointment.state != 8) ||
                editmode ||
                focusBlock || 
                isOfficeIpom || 
                isOfficeScheduleBlocked
              "
              >Przełóż wizytę</b-button>
            </b-notification>
            <b-notification
              type="is-warning"
              animation="none"
              class="mb-1"
              has-icon
              v-else-if="block.appointment.state == 100"
            >
              <b>Uwaga! Rezerwacja wstępna</b><br />
              Do wykonania wizyty niezbędne jest uzupełnienie wszystkich danych.
            </b-notification>
            <b-notification
              type="is-danger"
              animation="none"
              class="mb-1"
              v-else-if="(block.appointment.state < 100 && block.appointment.state > 4)"
            >
              <b>Wizyta się już odbyła.</b>
            </b-notification>
            <b-notification
              animation="none"
              type="is-danger"
              class="mb-1"
              v-else-if="isHistory"
            >
              <b>Wizyta historyczna.</b>
              <span v-if="block.appointment.state == 1">
                <br />Wizyta rozpoczęta, lecz niezakończona. Przełożenie niedostępne.
              </span>
            </b-notification>
            <b-notification
              animation="none"
              type="is-secondary"
              class="mb-1"
              v-if="isHistory && block.appointment.state == 0"
            >
              <b>Wizyta nie została rozpoczęta.</b>
              <b-button
                class="button is-pulled-right"
                size="is-small"
                rounded
                type="is-primary"
                icon-left="swap-horizontal-bold"
                @click="reschedule"
                :loading="requestInProgress"
                :disabled="readonly ||
                ((block.appointment.state < 100 && block.appointment.state > 4) && block.appointment.state != 8) ||
                (block.appointment.state > 0 && isHistory && block.appointment.state != 8) ||
                editmode ||
                focusBlock || 
                isOfficeIpom || 
                isOfficeScheduleBlocked
              "
              >Przełóż wizytę</b-button>
            </b-notification>

            <table class="table is-bordered is-narrow w-100">
              <tr>
                <th style="width: 12rem;">Data</th>
                <th>
                  <b-icon
                    icon="calendar"
                    size="is-small"
                    class="mb-1 mr-2"
                  ></b-icon>
                  {{ mDate(block.appointment.start) }}
                </th>
              </tr>
              <tr>
                <td>Godzina</td>
                <td>
                  <div v-if="editmode">
                    <b-notification
                      type="is-danger"
                      v-if="editmode && minDurationEditedVisit > 5 && duration < minDurationEditedVisit"
                    >
                      <span>Uwaga! Wizyta nie może trwać krócej niż {{minDurationEditedVisit}} minut.</span>
                    </b-notification>
                    <b-notification
                      type="is-warning"
                      v-if="editmode && minDurationEditedVisitInfo > 5 && duration < minDurationEditedVisitInfo"
                    >
                      <span>Uwaga! Czas wizyty jest niższy niż {{minDurationEditedVisitInfo}} minut.</span>
                    </b-notification>
                    <b-field grouped>
                      <b-field
                        label="Godzina wizyty"
                        label-position="on-border"
                        class="mr-0"
                      >
                        <p class="control">
                          <vue-timepicker
                            v-model="timeObject"
                            :minute-interval="5"
                            hide-disabled-items
                            hide-clear-button
                            :hour-range="enabledHours"
                            :minute-range="enabledMinutes"
                          ></vue-timepicker>
                        </p>
                      </b-field>
                      <b-field
                        label="Czas trwania"
                        label-position="on-border"
                        class="mr-0"
                        v-if="!block.appointment.isTimeless"
                      >
                        <b-numberinput
                          :editable="false"
                          v-model="duration"
                          :min="minDurationEditedVisit"
                          :max="maxDurationEditedVisit"
                          step="5"
                          type="is-xxx"
                          controls-position="compact"
                          controls-alignment="right"
                        ></b-numberinput>
                      </b-field>
                      <b-button
                        class="ml-0"
                        type="is-text"
                        style="text-decoration: none !important"
                        v-if="!block.appointment.isTimeless"
                      >
                        max {{ maxDurationEditedVisit }} minut
                      </b-button>
                    </b-field>
                  </div>
                  <div v-else>
                    <b-icon
                      icon="clock"
                      size="is-small"
                      class="mb-1 mr-2"
                    ></b-icon>
                    <span v-if="block.appointment.isTimeless">Bez godziny, orientacyjnie: {{ mTime(block.appointment.start) }}</span>
                    <span v-else>{{ mTime(block.appointment.start) }} - {{ mTime(block.appointment.stop) }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Sposób wykonania</td>
                <td>
                  <b-select
                    v-if="editmode"
                    expanded
                    v-model="appCopy.service"
                  >
                    <option
                      v-for="wayItem in getAppWays(appCopy.type)"
                      :key="wayItem.id"
                      :value="wayItem.id"
                    >{{wayItem.name}}</option>
                  </b-select>
                  <p v-else>
                    <b-icon
                      :icon="mGetServiceIcon(block.appointment.service)"
                      size="is-small"
                      class="mb-1 mr-2"
                    ></b-icon>
                    {{ getServiceTypeLabel(block.appointment.service) }}
                  </p>
                </td>
              </tr>

              <tr>
                <td>Placówka</td>
                <td>
                  <b-icon
                    icon="home"
                    size="is-small"
                    class="mb-1 mr-2"
                  ></b-icon>
                  {{getFacilityNameByInternalId(block.appointment.facility)}}
                </td>
              </tr>
              <tr>
                <td>Typ</td>
                <td>
                  <div v-if="editmode">
                    <b-field
                      expanded
                      label="Typ wizyty"
                      label-position="on-border"
                    >
                      <b-select
                        expanded
                        placeholder="Brak typów wizyt dla wybranego pracownika"
                        @input="onTypeChanged"
                        v-model="appointmentObjectTmp"
                        :disabled="block.appointment.type === 92 || block.appointment.type === 94"
                      >
                        <option
                          v-for="item in filteredAppTypes"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.name }}
                          <span v-if="item.apkolce">[AP-KOLCE]</span>
                        </option>
                      </b-select>
                      <p
                        class="control"
                        v-if="(block.worktimeArea && block.worktimeArea.officeId != null) ||  (block.worktimeArea === null && block.appointment.officeId)"
                      >
                        <b-checkbox-button
                          :native-value="true"
                          v-model="showAllAppTypes"
                        >
                          <div v-if="showAllAppTypes">
                            <b-icon icon="account-circle"></b-icon>
                          </div>
                          <div v-else>
                            <b-icon icon="home"></b-icon>
                          </div>
                        </b-checkbox-button>
                      </p>
                    </b-field>
                    <b-field
                      v-if="subcategories && subcategories.length > 0"
                      label="Podtyp"
                      label-position="on-border"
                    >
                      <b-select
                        placeholder="Wybierz"
                        v-model="appCopy.subtype"
                        @input="onSubTypeChanged"
                        expanded
                      >
                        <option
                          v-for="item in subcategories"
                          :key="item.id"
                          :value="item.id"
                        >{{ item.name }}</option>
                      </b-select>
                    </b-field>
                  </div>
                  <p v-else>
                    <b-icon
                      :icon="getAppIcon(block.appointment.type)"
                      size="is-small"
                      class="mb-1 mr-2"
                    ></b-icon>
                    {{ getAppName(block.appointment.type) }}
                    <span v-if="block.appointment.subtype > -1">
                      <br />
                      <b-icon
                        :icon="getAppIcon(block.appointment.type)"
                        size="is-small"
                        class="mb-1 mr-2"
                      ></b-icon>
                      {{ getAppSubCategory( block.appointment.type, block.appointment.subtype) }}
                    </span>
                  </p>
                </td>
              </tr>

              <tr v-if="isRISOffice && block.appointment.ticketInfo">
                <td>Usługa</td>
                <td>
                  <p v-if="block.appointment.ticketInfo.ikz">
                    <span
                      v-for="item in block.appointment.ticketInfo.ikz"
                      :key="item.id"
                    >{{item.name}}</span>
                  </p>
                </td>
              </tr>

              <tr v-if="isRISOffice && block.appointment.ticketInfo">
                <td>Badania</td>
                <td>
                  <p v-if="block.appointment.ticketInfo && block.appointment.ticketInfo.procedures && block.appointment.ticketInfo.procedures.length >0">
                  <ul>
                    <li
                      v-for="(item, index) in block.appointment.ticketInfo.procedures"
                      :key="index"
                    ><span>{{item.description}}</span>
                      <span v-if="item.bodySide">
                        , strona ciała:
                        <span class="has-text-weight-bold">
                          {{ item.bodySide }}
                        </span>
                      </span>
                      <span v-if="
                          item.icd9 != null &&
                          item.icd9 != ''
                        "> ({{ item.icd9 }})</span>
                      <span v-if="(index+1)<block.appointment.ticketInfo.length">, </span>
                      <div
                        style="font-size: 0.9rem;"
                        v-if="item.params && item.params.length>0"
                      >
                        Projekcje:
                        <span
                          v-for="x in item.params"
                          :key="x.index"
                        >{{mGetProjectionName(x)}}, </span>
                      </div>
                    </li>
                  </ul>
                  </p>
                  <p v-else>-</p>
                </td>
              </tr>

              <tr v-if="(block.appointment.type === 92 || block.appointment.type === 94) && block.appointment.ticketInfo">
                <td>Badania</td>
                <td>
                  <div v-if="block.appointment.ticketInfo.procedures && block.appointment.ticketInfo.procedures.length > 0">
                    <ul>
                      <li
                        v-for="(item, index) in block.appointment.ticketInfo.procedures"
                        :key="index"
                      >
                        <span>{{item.description}}</span>
                        <span v-if="
                              item.icd9 != null &&
                              item.icd9 != ''
                            "> ({{ item.icd9 }})
                        </span>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>

              <tr v-if="(block.appointment.type === 1000 || 
                block.appointment.type === 1012 || 
                block.appointment.type === 1023 || 
                block.appointment.type === 10000 || 
                block.appointment.type === 10001) && block.appointment.ticketInfo && block.appointment.ticketInfo.referral">
                <td>Numer karty DILO</td>
                <td>{{block.appointment.ticketInfo.referral.diloNumber}}</td>
              </tr>

              <tr>
                <td>Płatnik</td>
                <td>
                  <div v-if="editmode">
                    <b-select
                      expanded
                      class="mb-3"
                      v-model="appCopy.payerType"
                    >
                      <option
                        v-for="payerItem in getAppPayers(appCopy.type)"
                        :key="payerItem.id"
                        :value="payerItem.id"
                      >{{payerItem.name}}</option>
                    </b-select>

                    <b-switch
                      expanded
                      :rounded="false"
                      type="is-success"
                      v-if="appCopy.isPrivate"
                      v-model="appCopy.isPaid"
                    >Opłacona</b-switch>

                    <b-field
                      expanded
                      v-if="appCopy.isPaid"
                      class="mt-3"
                      label="Identyfikator płatności"
                      label-position="on-border"
                    >
                      <b-input
                        expanded
                        v-model="appCopy.paymentId"
                        maxlength="128"
                        :has-counter="false"
                      ></b-input>
                    </b-field>
                  </div>
                  <p v-else>{{ getAppointmentPayerType(block.appointment.payerType) }}</p>
                </td>
              </tr>

              <!-- <tr>
                <td>Powód wizyty</td>
                <td>
                  <b-field v-if="editmode">
                    <b-input
                      v-model="appCopy.reason"
                      placeholder="Powód wizyty"
                    ></b-input>
                  </b-field>
                  <p v-else-if="block.appointment.reason">{{ block.appointment.reason }}</p>
                  <p v-else>Brak</p>
                </td>
              </tr> -->

              <tr v-if="block.appointment.payerType == 1">
                <td>Świadczenia/usługi</td>
                <td>
                  <!-- <b-field v-if="editmode">
                    <b-input v-model="appCopy.description" placeholder="Dodatkowe uwagi"></b-input>
                  </b-field> -->
                  <div v-if="block.appointment.medServices">
                    <p
                      v-for="med in block.appointment.medServices"
                      :key="med.index"
                    >{{getMedService(med)}}</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td>Dodatkowe uwagi</td>
                <td>
                  <b-field v-if="editmode">
                    <b-input
                      v-model="appCopy.description"
                      placeholder="Dodatkowe uwagi"
                    ></b-input>
                  </b-field>
                  <p v-else-if="block.appointment.description">{{ block.appointment.description }}</p>
                  <p v-else>Brak</p>
                </td>
              </tr>

              <tr>
                <td>Inne</td>
                <td>
                  <div v-if="editmode">
                    <b-switch
                      type="is-danger"
                      v-model="appCopy.isUrgent"
                      :rounded="false"
                    >Pilne</b-switch>
                  </div>
                  <div v-else>
                    <p
                      v-if="block.appointment.patientWaiting"
                      class="has-text-orange"
                    >Pacjent czeka w poczekalni</p>
                    <p
                      v-if="block.appointment.isUrgent"
                      class="has-text-danger"
                    >Pilne!</p>
                    <p v-if="block.appointment.isPrivate">Prywatna</p>
                  </div>
                  <div v-if="!editmode">
                    <p v-if="block.appointment.isPrivate">Opłacona:
                      <b-tag
                        v-if="block.appointment.isPrivate && block.appointment.isPaid"
                        type="is-success"
                      >Tak</b-tag>
                      <b-tag
                        v-if="block.appointment.isPrivate && !block.appointment.isPaid"
                        type="is-danger"
                      >Nie</b-tag>
                    </p>
                  </div>
                </td>
              </tr>
            </table>

            <table
              class="table is-narrow is-bordered w-100 mb-4"
              v-if="block.appointment.payerType == 1"
            >

              <tr>
                <th colspan="2">Informacja NFZ</th>
              </tr>
              <tr>
                <td>Oddział NFZ pacjenta</td>
                <td>{{mGetBranchNfzNameParsed(patient.nfzDepartment)}}</td>
              </tr>
              <tr v-if="currentOffice">
                <td>Oddział NFZ gabinetu</td>
                <td>
                  <span>{{mGetBranchNfzNameParsed(currentOffice.nfzCode)}}</span>
                </td>
              </tr>
              <tr>
                <td>Deklaracje</td>
                <td>
                  <p :class="{'has-text-grey':patient.deklL < 1}">
                    {{getDeklPersonel(patient.deklL)}} <span class="is-size-7">(lekarz)</span>
                  </p>
                  <p :class="{'has-text-grey':patient.deklP < 1}">
                    {{getDeklPersonel(patient.deklP)}} <span class="is-size-7">(pielęgniarka)</span>
                  </p>
                  <p :class="{'has-text-grey':patient.deklO < 1}">
                    {{getDeklPersonel(patient.deklO)}} <span class="is-size-7">(położna)</span>
                  </p>
                </td>
              </tr>

            </table>

          </div>
          <div class="column is-6">
            <table
              class="table is-narrow is-bordered w-100 mb-4"
              v-if="block.appointment.isGroup && block.appointment.groupPatients != null"
            >
              <tr>
                <th>Pacjenci</th>
                <th>
                  <div class="content">
                    <ul class="mb-2 mt-2">
                      <li
                        v-for="item in groupPatients"
                        :key="item.index"
                      >
                        {{ item.lastName }} {{ item.firstName }}
                        {{ item.pesel ? `, PESEL: ${item.pesel}` : '' }}
                        {{ item.identityDocument === 0 && item.identityDocumentNumber ? `, dowód osobisty: ${item.identityDocumentNumber}` : ''}}
                        {{ item.identityDocument === 1 && item.identityDocumentNumber ? `, paszport: ${item.identityDocumentNumber}` : ''}}
                        <a
                          class="is-size-7 has-text-danger"
                          @click="removePatientFromGroup(item)"
                        >Usuń</a>
                      </li>
                      <li v-if="block.appointment.groupPatients.length < 1">Brak</li>
                    </ul>
                  </div>
                </th>
              </tr>
            </table>
            <table
              class="table is-narrow is-bordered w-100 mb-4"
              v-else-if="patient"
            >
              <tr>
                <th style="width: 12rem;">Pacjent</th>
                <th class="has-text-primary has-text-weight-bold">
                  {{ patient.lastName }} {{ patient.firstName }}

                  <b-tooltip
                    class="is-inline is-pulled-right"
                    label="Edytuj dane pacjenta"
                    position="is-left"
                  >
                    <b-button
                      :disabled="registrationPatient && registrationPatient.id !== patient.id"
                      icon-left="account-edit"
                      size="is-small"
                      @click="emitEditPatientData"
                    >Edytuj dane
                    </b-button>
                  </b-tooltip>
                </th>
              </tr>
              <tr v-if="patient">
                <th>Wiek pacjenta</th>
                <th>
                  {{ patient.age }}
                  <b-tag
                    :type="ageRestriction ? 'is-danger' : 'is-secondary'"
                    class="is-pulled-right has-text-semibold"
                    v-if="appointmentObject && appointmentObject.hasAgeRestriction"
                  >
                    Wybrany typ wizyty wymaga wieku od
                    <b>{{ ageFrom }}</b> do
                    <b>{{ageTo}}</b> lat
                  </b-tag>
                </th>
              </tr>
              <tr v-if="patient.pesel">
                <td>PESEL</td>
                <td>{{patient.pesel}}</td>
              </tr>
              <tr v-if="patient.identityDocument === 0 && patient.identityDocumentNumber">
                <td>Dowód osobisty</td>
                <td>{{patient.identityDocumentNumber}}</td>
              </tr>
              <tr v-if="patient.identityDocument === 1 && patient.identityDocumentNumber">
                <td>Paszport</td>
                <td>{{patient.identityDocumentNumber}}</td>
              </tr>
              <tr>
                <td>Adres</td>
                <td>
                  {{ patient.addressStreet ? patient.addressStreet : "-" }}
                  {{ patient.addressHouseNumber ? patient.addressHouseNumber : "-" }}
                  {{ patient.addressApartmentNumber ? `/ ${patient.addressApartmentNumber},` : "," }}
                  {{ patient.addressZipCode ? patient.addressZipCode : "-" }}
                  {{ patient.addressCity ? patient.addressCity : "-" }}
                </td>
              </tr>
              <tr>
                <td>Numer telefonu</td>
                <td>
                  <b-tooltip
                    position="is-right"
                    label="Upewnij się że wpisany numer jest poprawny"
                    type="is-primary"
                    v-if="phoneNumberWarning"
                  >
                    <b-icon
                      icon="information-outline"
                      style="color: orange"
                    ></b-icon>
                  </b-tooltip>
                  <b-tooltip
                    position="is-right"
                    label="Upewnij się że numer kierunkowy do Polski nie został zdublowany"
                    type="is-primary"
                    v-if="phoneNumberPrefixWarning"
                  >
                    <b-icon
                      icon="information-outline"
                      type="is-warning"
                    ></b-icon>
                  </b-tooltip>
                  <span v-if="patient.teleAddressTelephoneNumber">
                    {{patient.teleAddressTelephoneNumber}}
                  </span>
                  <span
                    v-else
                    class="has-text-danger has-text-weight-bold"
                  >
                    Brak numeru telefonu
                  </span>

                  <SmsPatientHistory
                    :patient="patient"
                    style="margin: -1px -1px -1px 5px;"
                    class="is-inline is-pulled-right ml-3"
                  ></SmsPatientHistory>

                  <PhoneVerification
                    :patient="patient"
                    @validated="patientPhoneValidated"
                    style="margin: -1px -1px -1px 5px;"
                    class="is-inline is-pulled-right ml-3"
                  ></PhoneVerification>

                  <SmsGateModal
                    v-if="block && block.appointment"
                    @sent="addSms"
                    :patient="patient"
                    :phone="patient.teleAddressTelephoneNumber"
                    :appointment="block.appointment"
                    style="margin: -1px -1px -1px 5px;"
                    class="is-inline is-pulled-right ml-3"
                  ></SmsGateModal>
                </td>
              </tr>
              <tr v-if="patient.teleAddressTelephoneNumber">
                <td>Właściciel numeru</td>
                <td>{{patient.phoneNumberOwner ? patient.phoneNumberOwner : "brak informacji"}}
                  <span v-if="patient.phoneNumberOtherOwner"> {{patient.phoneNumberOtherOwner}}</span>
                </td>
              </tr>
            </table>

            <table class="table is-narrow is-bordered mb-4 w-100">
              <tr>
                <th style="width: 12rem;">Pracownik wykonujący</th>
                <th colspan="2">
                  <span v-if="employee">{{ employee.lastName }} {{ employee.firstName }}</span>
                  <b-skeleton
                    v-else
                    width="100%"
                    size="is-large"
                    :animated="true"
                  ></b-skeleton>
                </th>
              </tr>
              <tr>
                <td>Pracownik zapisujący</td>
                <td>
                  <span v-if="employeeCreated">{{ employeeCreated.lastName }} {{ employeeCreated.firstName }}</span>
                </td>
                <td>{{ mDateTime(block.appointment.created) }} </td>
              </tr>
              <tr>
                <td>Ostatnia edycja</td>
                <td>
                  <span v-if="employeeEdit">{{ employeeEdit.lastName }} {{ employeeEdit.firstName }}</span>
                </td>
                <td>
                  {{ mDateTime(block.appointment.modified) }}
                </td>
              </tr>
              <tr v-if="room">
                <td>Pokój</td>
                <td colspan="2">{{ room.nameFull ? room.nameFull : "Brak" }}</td>
              </tr>
            </table>

            <table class="table is-narrow is-bordered mb-4 w-100">
              <tr>
                <th>EWUS</th>
                <th>Data</th>
                <th>Status</th>
                <th>DN</th>
              </tr>
              <tr v-if="block.appointment.sEwus">
                <td>Data wizyty</td>
                <td>{{mDate(block.appointment.sEwus.dateExp)}}</td>
                <td
                  class="has-text-weight-semibold"
                  :class="getEwusTextColor(block.appointment.sEwus.ewusStatus)"
                >{{getEwusStatus(block.appointment.sEwus.ewusStatus)}}</td>
                <td>{{getEwusDN(block.appointment.sEwus.ewusDN)}}</td>
              </tr>
              <tr v-if="block.appointment.lEwus">
                <td>Ostatnio</td>
                <td>{{mDate(block.appointment.lEwus.dateExp)}}</td>
                <td
                  class="has-text-weight-semibold"
                  :class="getEwusTextColor(block.appointment.lEwus.ewusStatus)"
                >{{getEwusStatus(block.appointment.lEwus.ewusStatus)}}</td>
                <td>{{getEwusDN(block.appointment.lEwus.ewusDN)}}</td>
              </tr>
            </table>

            <ContentPanel
              v-if="patient && block.appointment.ticketInfo && (block.appointment.ticketInfo.referral || block.appointment.ticketInfo.referralResultsObject)"
              title="Skierowanie"
              icon="alpha-s-circle-outline"
              class="mt-3"
              :colapse="true"
            >
              <div v-if="block.appointment.ticketInfo.referral">
                <template v-if="block.appointment.ticketInfo.referral.isPaperReferral == true">
                  <template v-if="block.appointment.ticketInfo.referral.idReferralFile != null">

                    <b-button
                      type="is-primary"
                      @click="
                          getDownloadedFile(block.appointment.ticketInfo.referral.idReferralFile)
                        "
                      icon-left="magnify"
                      :loading="loading"
                    >Podgląd skierowania papierowego</b-button>

                  </template>
                  <template v-else>Brak skanu skierownia</template>
                </template>
                <template v-else>
                  <template v-if="block.appointment.ticketInfo.referral.eReferral != null">
                    <div class="buttons">
                      <b-button
                        type="is-primary"
                        v-if="block.appointment.ticketInfo.referral.eReferral.eSkierowanie"
                        @click="mPrintReferralHtml('printEReferralIframe', block.appointment.ticketInfo.referral.eReferral.eSkierowanie)"
                        icon-left="printer"
                        :loading="loading"
                      >Drukuj e-skierowanie</b-button>
                      <b-button
                        v-else
                        type="is-primary"
                        @click="printEReferral"
                        icon-left="printer"
                        disabled
                        :loading="loading"
                      >Podgląd skanu tymczasowo niedostępny</b-button>
                    </div>

                    <iframe
                      v-if="block.appointment.ticketInfo.referral.eReferral.eSkierowanie"
                      :srcdoc="block.appointment.ticketInfo.referral.eReferral.eSkierowanie"
                      id="printEReferralIframe"
                      name="printEReferralIframe"
                      style="width:100%;height:24rem;"
                    >
                    </iframe>
                    <div v-else>
                      <table
                        class="table is-bordered"
                        v-if="block.appointment.ticketInfo.referral.eReferralPaper"
                      >
                        <tr>
                          <th colspan="2">Skierowanie</th>
                        </tr>
                        <tr>
                          <td>OID</td>
                          <td>{{block.appointment.ticketInfo.referral.eReferralPaper.rootOID}}</td>
                        </tr>
                        <tr>
                          <td>Numer</td>
                          <td>{{block.appointment.ticketInfo.referral.eReferralPaper.referralNr}}</td>
                        </tr>
                        <tr>
                          <td>Kod</td>
                          <td>{{block.appointment.ticketInfo.referral.eReferralPaper.referralCode}}</td>
                        </tr>
                      </table>
                    </div>

                    <!-- <div
                      id="summary"
                      v-html="block.appointment.ticketInfo.referral.eReferral.eSkierowanie"
                    ></div>

                    <div style="display: none;">
                      <div>
                        <div
                          id="printEReferral"
                          v-html="block.appointment.ticketInfo.referral.eReferral.eSkierowanie"
                        ></div>
                      </div>
                    </div>  -->
                  </template>
                  <template v-else>
                    <div>Błąd skierowania</div>
                  </template>
                </template>
              </div>
              <div v-if="block.appointment.ticketInfo.referralResultsObject">
                <template v-if="block.appointment.ticketInfo.referralResultsObject.fileHtml">
                  <div class="buttons">
                    <b-button
                      type="is-primary"
                      v-if="block.appointment.ticketInfo.referralResultsObject.fileHtml"
                      @click="mPrintReferralHtml('printEReferralIframe', block.appointment.ticketInfo.referralResultsObject.fileHtml)"
                      icon-left="printer"
                      :loading="loading"
                    >Drukuj e-skierowanie</b-button>
                    <b-button
                      v-else
                      type="is-primary"
                      @click="printEReferral"
                      icon-left="printer"
                      disabled
                      :loading="loading"
                    >Podgląd skanu tymczasowo niedostępny</b-button>
                  </div>

                  <iframe
                    v-if="block.appointment.ticketInfo.referralResultsObject.fileHtml"
                    :srcdoc="block.appointment.ticketInfo.referralResultsObject.fileHtml"
                    id="printEReferralIframe"
                    name="printEReferralIframe"
                    style="width:100%;height:24rem;"
                  >
                  </iframe>
                </template>
              </div>
            </ContentPanel>

            <ContentPanel
              v-if="block.appointment.ticketInfo && block.appointment.ticketInfo.apkolce"
              icon="alpha-s-circle-outline"
              title="APKolce"
              class="mt-3"
              :colapse="true"
              :paddingless="true"
            >
              <table
                v-if="block.appointment.ticketInfo.apkolce"
                class="table is-narrow mb-3"
                style="width: 100%"
              >
                <tr>
                  <th
                    colspan="2"
                    class="has-text-primary"
                  >APKolce</th>
                </tr>
                <tr>
                  <td>Przypadek:</td>
                  <td class="has-text-weight-medium">
                    {{block.appointment.ticketInfo.apkolce.isUrgent ? "Pilny" : "Stabilny"}}
                  </td>
                </tr>
                <tr>
                  <td>Status:</td>
                  <td class="has-text-weight-medium">
                    {{block.appointment.ticketInfo.apkolce.registrationType ? block.appointment.ticketInfo.apkolce.registrationType : "Brak"}}
                  </td>
                </tr>
                <tr v-if="block.appointment.ticketInfo.apkolce.icd10Code && block.appointment.ticketInfo.apkolce.icd10Name">
                  <td>Rozpoznanie główne:</td>
                  <td class="has-text-weight-medium">
                    <span>
                      <b-tag
                        type="is-primary"
                        class="ml-1 mr-1 has-text-weight-bold"
                      >
                        <b>{{ block.appointment.ticketInfo.apkolce.icd10Code }}</b>
                      </b-tag>
                      {{ block.appointment.ticketInfo.apkolce.icd10Name }}
                    </span>
                  </td>
                </tr>
                <tr v-if="block.appointment.ticketInfo.apkolce.icd10Additional">
                  <td>Rozpoznania dodatkowe:</td>
                  <td class="has-text-weight-medium">
                    <span
                      v-for="(item, index) in block.appointment.ticketInfo.apkolce.icd10Additional"
                      :key="index"
                    >
                      <b-tag
                        type="is-primary"
                        class="ml-1 mr-1 has-text-weight-bold"
                      >
                        {{ item.code }}
                      </b-tag>
                      {{ item.name }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Komentarz:</td>
                  <td class="has-text-weight-medium">
                    {{ block.appointment.ticketInfo.apkolce.comment ? block.appointment.ticketInfo.apkolce.comment : "Brak" }}
                  </td>
                </tr>
                <tr>
                  <td>Części ciała:</td>
                  <td class="has-text-weight-medium">
                    <span
                      v-for="(item, index) in block.appointment.ticketInfo.apkolce.bodyParts"
                      :key="index"
                    >
                      <b-tag
                        type="is-primary"
                        class="ml-1 mr-1 has-text-weight-bold"
                      >
                        {{ item.code }}
                      </b-tag>
                      {{ item.name }}
                    </span>
                  </td>
                </tr>
              </table>

              <table
                v-if="block.appointment.ticketInfo.referral && block.appointment.ticketInfo.referral.apkolce"
                class="table is-narrow mt-3"
                style="width: 100%"
              >
                <tr>
                  <th
                    colspan="2"
                    class="has-text-primary"
                  >Skierowanie APKolce</th>
                </tr>
                <tr>
                  <td>Nazwa podmiotu:</td>
                  <td class="has-text-weight-medium">
                    {{block.appointment.ticketInfo.referral.apkolce.subject}}
                  </td>
                </tr>
                <tr>
                  <td>REGON:</td>
                  <td class="has-text-weight-medium">
                    {{block.appointment.ticketInfo.referral.apkolce.regon}}
                  </td>
                </tr>
                <tr>
                  <td>Kod część I:</td>
                  <td class="has-text-weight-medium">
                    {{block.appointment.ticketInfo.referral.apkolce.code1st}}
                  </td>
                </tr>
                <tr>
                  <td>Kod część VII:</td>
                  <td class="has-text-weight-medium">
                    {{block.appointment.ticketInfo.referral.apkolce.code7th}}
                  </td>
                </tr>
                <tr>
                  <td>Kod część VIII:</td>
                  <td class="has-text-weight-medium">
                    {{block.appointment.ticketInfo.referral.apkolce.code8th}}
                  </td>
                </tr>
                <tr>
                  <td>Kod miejsca udzielania świadczeń:</td>
                  <td class="has-text-weight-medium">
                    {{block.appointment.ticketInfo.referral.apkolce.musCode}}
                  </td>
                </tr>
                <tr>
                  <td>Data wystawienia:</td>
                  <td class="has-text-weight-medium">
                    <span v-if="mDate(block.appointment.ticketInfo.referral.apkolce.issueDate) !== '0001-01-01'">
                      {{mDate(block.appointment.ticketInfo.referral.apkolce.issueDate)}}
                    </span>
                  </td>
                </tr>
              </table>
            </ContentPanel>

            <div
              v-if="block.appointment.sms"
              class="pb-2"
            >
              <p class="has-text-weight-medium has-text-primary mb-0">Wiadomości SMS:</p>
              <p
                class="pb-0"
                v-for="sms in block.appointment.sms"
                :key="sms.index"
              >
                {{ mDateTime(sms.created) }}: <i>{{ sms.content }}</i>
              </p>
            </div>

            <div
              v-if="editmode && block.appointment.type === 94 && block.appointment.state < 1
                    && block.appointment.ticketInfo"
              class="mb-1"
            >
              <CollectionPointExamChange
                @apps-trigger="changeCollectionPointExamsUpdate"
                :appointment="block.appointment"
              />
            </div>

            <div
              v-if="editmode && isRISOffice && block.appointment.state < 1
              && block.appointment.ticketInfo"
              class="mb-1"
            >
              <SchedulePatientChangeOrder
                @updated="changeOrderUpdate"
                :examination="block.appointment"
                :ticket="block.appointment.ticketInfo"
              ></SchedulePatientChangeOrder>
            </div>

            <div
              v-if="editmode && isRISOffice && block.appointment.state < 1"
              class="mb-1"
            >
              <SchedulePatientChangeIKZ
                @updated="changeIKZUpdate"
                :examination="block.appointment"
              ></SchedulePatientChangeIKZ>
            </div>

            <div
              v-if="editmode && isRISOffice && block.appointment.state < 1 
            && block.appointment.ticketInfo && block.appointment.ticketInfo.apkolce"
              class="mb-1"
            >
              <RISPatientChangeAPKolce
                @updated="changeAPKolceUpdate"
                :ticket="block.appointment.ticketInfo"
              ></RISPatientChangeAPKolce>
            </div>

            <div
              v-if="editmode && isRISOffice && block.appointment.state < 1 
            && block.appointment.ticketInfo && block.appointment.ticketInfo.referral && block.appointment.ticketInfo.referral.apkolce"
              class="mb-1"
            >
              <RISPatientChangeAPKolceReferral
                @updated="changeAPKolceReferralUpdate"
                :ticket="block.appointment.ticketInfo"
              ></RISPatientChangeAPKolceReferral>
            </div>

            <div v-if="editmode && block.appointment.state < 1 
              && (block.appointment.type === 1000 || 
              block.appointment.type === 1012 || 
              block.appointment.type === 1023 || 
              block.appointment.type === 10000 || 
              block.appointment.type === 10001)">
              <RISPatientChangeDILO
                @updated="changeDILOUpdate"
                :ticket="block.appointment.ticketInfo"
              ></RISPatientChangeDILO>
            </div>

          </div>
        </div>

        <!-- Print -->
        <div
          id="printDetails"
          ref="printDetails"
          style="display: none; margin-left: 1rem; margin-right: 1rem;"
        >
          <!-- <template v-if="subunit && facility">
            <DocsHeadlineSubunit
              :subunit="subunit"
              :facility="facility"
              :app="block.appointment"
            />
          </template>-->

          <p
            class="has-text-centered has-text-weight-bold is-size-4"
            style="
              margin-top: 20px;
              margin-bottom: 0px;
              padding-bottom: 0px;
              border-bottom: 3px solid black;
            "
          >UMÓWIONA WIZYTA</p>

          <div v-if="patient">
            <p class="is-size-6 has-text-weight-bold">
              {{ patient.firstName ? patient.firstName.toUpperCase() : ' - ' }}
              {{ patient.lastName ? patient.lastName.toUpperCase() : ' - ' }}
            </p>
            <!-- <p class="is-size-6">
              PESEL:
              <span class="has-text-weight-bold">{{ patient.pesel ? patient.pesel : '-' }}</span>
            </p>-->
            <p class="is-size-6">Data urodzenia: {{ patient.dateOfBirth ? mDate(patient.dateOfBirth) : '-' }}</p>
            <p class="is-size-6">
              {{ patient.addressStreet ? patient.addressStreet.toUpperCase() : '-' }}
              {{ patient.addressHouseNumber ? patient.addressHouseNumber : '-' }}
              {{ patient.addressApartmentNumber ? `/ ${patient.addressApartmentNumber},` : "," }}
              {{ patient.addressZipCode ? patient.addressZipCode : "-" }}
              {{ patient.addressCity ? patient.addressCity : "-" }}
            </p>
          </div>

          <p
            class="has-text-centered is-size-5"
            style="
              margin-top: 10px;
              margin-bottom: 10px;
              padding-bottom: 10px;
              border-bottom: 1px solid black;
            "
          >{{ mGetClinic(block.appointment.clinic).toUpperCase() }}</p>

          <p
            class="has-text-centered is-size-5"
            style="margin-bottom: 10px"
          >{{ getAppName(block.appointment.type).toUpperCase() }}</p>

          <p
            v-if="employee && !isRISOffice"
            class="has-text-centered is-size-5"
            style="margin-top: 30px; margin-bottom: 10px"
          >
            LEK. {{ employee.firstName ? employee.firstName.toUpperCase() : '-' }}
            {{ employee.lastName ? employee.lastName.toUpperCase() : '-' }}
          </p>

          <p
            class="has-text-centered is-size-5"
            style="margin-top: 30px"
          >
            Termin
            <span class="ml-3 has-text-weight-bold">
              {{
              getDate(block.appointment.start)
              }}
            </span>
          </p>
          <p
            class="has-text-centered is-size-5"
            style="margin-bottom: 10px"
          >
            Godzina
            <span class="ml-3 has-text-weight-bold">
              <span v-if="!block.appointment.isTimeless">{{ getHour(block.appointment.start) }}</span>
              <span v-else>-</span>
            </span>
          </p>

          <p
            v-if="room"
            class="has-text-centered is-size-5"
          >Pokój {{ room.nameFull ? room.nameFull.toUpperCase() : '-' }}</p>

          <p
            v-if="facility"
            class="has-text-centered is-size-5"
            style="margin-bottom: 15px"
          >{{ facility.name ? facility.name.toUpperCase() : "-" }}</p>

          <p
            v-if="facility"
            class="has-text-centered is-size-5"
          >
            {{ facility.address.street ? facility.address.street.toUpperCase() : '-' }}
            {{ facility.address.buildingNumber ? facility.address.buildingNumber : '-' }}
            {{ facility.address.apartmentNumber ? "/ " : "" }}
            {{ facility.address.apartmentNumber }}
            {{ facility.address.city ? facility.address.city.toUpperCase() : '-' }}
            {{ facility.address.zipCode ? ", " : "" }}
            {{ facility.address.zipCode ? facility.address.zipCode.toUpperCase() : '-' }}
            {{ facility.address.country ? facility.address.country.toUpperCase() : '-' }}
          </p>
        </div>
        <p class="has-text-centered">
          <b-button
            v-if="block.appointment.isGroup"
            type="is-secondary"
            icon-left="alert"
            class="mt-3"
            rounded
            size="is-small"
            @click="splitGroup"
            :disabled="block.appointment.groupPatients.length < 1"
          >Rozdziel na wizyty pojedyncze</b-button>
        </p>
      </section>
      <section
        class="modal-card-body pl-5 pr-5 has-text-dark"
        v-else-if="appointment && isVehicle"
      >
        <div class="columns">
          <div class="column">
            <p class="has-text-weight-medium has-text-primary mb-0">Pojazd</p>
            <p class="subtitle">
              <span style="word-wrap: break-all; white-space: pre-wrap">{{ getVehicle(block.appointment.vehicle) }}</span>
            </p>

            <p class="has-text-weight-medium has-text-primary mb-0">Typ</p>
            <div v-if="editmode">
              <b-field
                expanded
                label="Typ rezerwacji"
                label-position="on-border"
              >
                <b-select
                  v-model="appCopy.type"
                  expanded
                >
                  <option :value="-1">Brak</option>
                  <option
                    v-for="item in vehicleApps"
                    :key="item.id"
                    :value="item.id"
                  >{{ item.name }}</option>
                </b-select>
              </b-field>
            </div>
            <p
              v-else
              class="subtitle"
            >
              <span style="word-wrap: break-all; white-space: pre-wrap">{{ getMobiReservationType(block.appointment.type) }}</span>
            </p>

            <p class="has-text-weight-medium has-text-primary mb-0">Deklarowany kierowca</p>
            <div v-if="editmode">
              <b-field
                expanded
                label="Deklarowany kierowca"
                label-position="on-border"
              >
                <b-autocomplete
                  :data="elmpoyeeFiltered"
                  v-model="employeeFilter"
                  placeholder="Wybierz"
                  open-on-focus
                  :custom-formatter="(object) => {return `${object.lastName} ${object.firstName}` }"
                  keep-first
                  expanded
                  @select="(option) => appCopy.employee = option ? option.id : -1"
                  :loading="requestInProgress"
                  clearable
                >
                  <template slot-scope="props">
                    <p :class="{
                          'has-text-success has-text-weight-bold':
                            appCopy.employee === props.option.id,
                        }">
                      {{ props.option.lastName }}
                      {{ props.option.firstName }}
                    </p>
                  </template>
                </b-autocomplete>
              </b-field>
            </div>
            <p
              v-else
              class="subtitle"
            >{{ getEmployee(block.appointment.employee) }}</p>

            <p class="has-text-weight-medium has-text-primary mb-0">Data wyjazdu</p>
            <p class="subtitle">{{ mDateTime(block.appointment.start) }}</p>

            <p class="has-text-weight-medium has-text-primary mb-0">Data przyjazdu</p>
            <p class="subtitle">{{ mDateTime(block.appointment.stop) }}</p>
          </div>
          <div class="column">
            <p class="has-text-weight-medium has-text-primary mb-0">Placówka</p>
            <div v-if="editmode && vehicleReservation">
              <div>
                <p class="mb-2">
                  Placówka:
                  <b-switch
                    size="is-small"
                    v-model="vehicleReservation.reservationDetails.internalClinic"
                  >
                    {{
                    vehicleReservation.reservationDetails.internalClinic == true
                    ? "Placówka CMD"
                    : "Placówka inna"
                    }}
                  </b-switch>
                </p>
              </div>
              <b-field
                label="Miejsce"
                label-position="on-border"
              >
                <b-autocomplete
                  v-if="vehicleReservation.reservationDetails.internalClinic"
                  :data="facilitiesFiltered"
                  v-model="facilityFilter"
                  clearable
                  open-on-focus
                  field="name"
                  placeholder="Wybierz"
                  keep-first
                  @select="(option) => vehicleReservation.reservationDetails.destinationClinic = option ? option.internalId : -1"
                >
                  <template slot-scope="props">
                    <p :class="{
                                'has-text-success has-text-weight-bold':
                                  vehicleReservation.reservationDetails.destinationClinic === props.option.id,
                              }">{{ props.option.name }}</p>
                    <p>
                      {{ props.option.address.street }}
                      {{ props.option.address.buildingNumber }}
                      {{ props.option.address.apartmentNumber ? "/ " : "" }}
                      {{ props.option.address.apartmentNumber }}
                    </p>
                    <p>
                      {{ props.option.address.city }}
                      {{ props.option.address.zipCode ? ", " : "" }}
                      {{ props.option.address.zipCode }}
                    </p>
                  </template>
                </b-autocomplete>
                <b-input
                  v-else
                  v-model="vehicleReservation.reservationDetails.destinationText"
                  placeholder="Nazwa placówki"
                />
              </b-field>
            </div>
            <p
              v-else
              class="subtitle"
            >{{ vehicleReservationDetails.internalClinic && vehicleReservationDetails.destinationClinic !== -1 ? getFacilityNameByInternalId(vehicleReservationDetails.destinationClinic) : vehicleReservationDetails.destinationText }}</p>

            <p class="has-text-weight-medium has-text-primary mb-0">Liczba osób</p>
            <b-field
              v-if="editmode && vehicleReservation"
              label="Liczba osób"
              label-position="on-border"
            >
              <b-input
                type="number"
                v-model="vehicleReservation.reservationDetails.peopleNumber"
              />
            </b-field>
            <p
              v-else
              class="subtitle"
            >{{ vehicleReservationDetails.peopleNumber }}</p>

            <p class="has-text-weight-medium has-text-primary mb-0">Potrzebna przestrzeń dodatkowa</p>
            <div v-if="editmode && vehicleReservation">
              <b-field>
                <b-checkbox v-model="vehicleReservation.reservationDetails.isAdditionalSpace">Potrzebna dodatkowa przestrzeń ładunkowa</b-checkbox>
              </b-field>

              <b-field
                v-if="vehicleReservation.reservationDetails.isAdditionalSpace"
                label="Co chcesz przewieźć?"
                label-position="on-border"
              >
                <b-input v-model="vehicleReservation.reservationDetails.additionalSpace" />
              </b-field>
            </div>
            <p
              v-else
              class="subtitle"
            >{{ vehicleReservationDetails.isAdditionalSpace ? `Tak, ${vehicleReservationDetails.additionalSpace}` : `Nie` }}</p>

            <p class="has-text-weight-medium has-text-primary mb-0">Uwagi</p>
            <b-field
              v-if="editmode && vehicleReservation"
              label="Uwagi"
              label-position="on-border"
            >
              <b-input
                type="textarea"
                v-model="vehicleReservation.reservationDetails.notes"
              />
            </b-field>
            <p
              v-else
              class="subtitle"
            >{{ vehicleReservationDetails.notes }}</p>
          </div>
        </div>
      </section>
      <footer
        v-if="isVehicle"
        class="modal-card-foot"
      >
        <b-button
          icon-left="calendar-remove"
          :loading="requestInProgress"
          class="button is-danger"
          @click="cancelAppointmentVehicle"
          :disabled="readonly ||
            (block.appointment.state < 100 && block.appointment.state > 4) ||
            (block.appointment.state > 0 && isHistory) ||
            (block.appointment.state === 1) ||
            editmode ||
            focusBlock
          "
        >Odwołaj wizytę</b-button>

        <b-button
          class="button is-primary"
          v-if="editmode"
          icon-left="check"
          :loading="requestInProgress"
          :disabled="
          (
            !this.block.appointment.isTimeless &&
            (
            maxDurationEditedVisit < duration ||
            duration < minDurationEditedVisit ||
            duration % 5 != 0
            )
          )
          "
          @click="saveUpdatesVehicle"
        >Zapisz zmiany</b-button>
        <b-button
          type="is-warning"
          v-if="editmode"
          icon-left="close"
          :loading="requestInProgress"
          @click="editmodeCancel"
        >Anuluj</b-button>
        <b-button
          class="button is-primary"
          v-else
          :loading="requestInProgress"
          @click="editmode = true"
          icon-left="pencil"
          :disabled="(block.appointment.state < 100 && block.appointment.state > 4) || isHistory"
        >Edytuj informacje</b-button>
        <!-- <b-button
          expanded
          icon-left="printer"
          type="is-primary"
          size="is-small"
          @click="print"
          :loading="loadingSubunit"
          :disabled="editmode"
        >Drukuj</b-button>-->
        <!-- <ScheduleModalAppointmentDetailsPrint :appointment="block.appointment" :employee="employee"/> -->
      </footer>
      <footer
        v-else
        class="modal-card-foot p-2"
      >
        <b-button
          icon-left="calendar-remove"
          :loading="requestInProgress"
          class="button is-danger"
          @click="isCancelAppointmentModalActive = true"
          :disabled="readonly ||
            (block.appointment.state < 100 && block.appointment.state > 4) ||
            (block.appointment.state > 0 && isHistory) ||
            (block.appointment.state === 1) ||
            editmode ||
            focusBlock || 
            isOfficeIpom || 
            isOfficeScheduleBlocked
          "
        >Odwołaj wizytę</b-button>
        <b-button
          class="button is-primary"
          icon-left="swap-horizontal-bold"
          @click="reschedule"
          :loading="requestInProgress"
          :disabled="readonly ||
            ((block.appointment.state < 100 && block.appointment.state > 4) && block.appointment.state != 8) ||
            (block.appointment.state > 0 && isHistory && block.appointment.state != 8 && block.appointment.state != 100) ||
            (block.appointment.state === 1 && isCollectionPointOffice) ||
            (block.appointment.state === 1 && isRISOffice) ||
            editmode ||
            focusBlock || 
            isOfficeIpom || 
            isOfficeScheduleBlocked
          "
        >Przełóż</b-button>

        <b-button
          class="button is-primary"
          v-if="editmode"
          icon-left="check"
          :loading="requestInProgress"
          :disabled="readonly ||
          (
            !this.block.appointment.isTimeless &&
            (
            maxDurationEditedVisit < duration ||
            duration < minDurationEditedVisit ||
            duration % 5 != 0
            )
          ) ||
            ageRestriction
          "
          @click="saveUpdates"
        >Zapisz zmiany</b-button>
        <b-button
          type="is-warning"
          v-if="editmode"
          icon-left="close"
          :loading="requestInProgress"
          @click="editmodeCancel"
        >Anuluj</b-button>
        <b-button
          class="is-secondary"
          v-else-if="block.appointment.state == 100"
          :loading="requestInProgress"
          @click="completeReservation"
          :disabled="readonly || isOfficeScheduleBlocked"
          icon-left="pencil"
        >Uzupełnij informacje wymagane do wizyty</b-button>
        <b-button
          class="button is-primary"
          v-else
          :loading="requestInProgress"
          @click="editmode = true"
          icon-left="pencil"
          :disabled="readonly ||block.appointment.state > 4 || isHistory || isOfficeIpom || isOfficeScheduleBlocked"
        >Edytuj informacje</b-button>
        <b-button
          icon-left="printer"
          type="is-primary"
          @click="print"
          :loading="loadingSubunit || requestInProgress || printLoading"
          :disabled="editmode"
        >Drukuj</b-button>
        <b-button
          v-if="perms.radiologyRegistration && isRisUSG && block.appointment.state < 1 && isToday"
          :loading="requestInProgress"
          @click="triggerSendExam += 1"
          type="is-orange"
          icon-left="forward"
        >
          Wyślij badanie USG do urządzenia
        </b-button>
        <!-- <ScheduleModalAppointmentDetailsPrint :appointment="block.appointment" :employee="employee"/> -->
      </footer>
    </div>

    <b-modal
      :active.sync="isCancelAppointmentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      scroll="keep"
    >
      <div
        class="modal-card"
        style="overflow: visible"
      >
        <header class="modal-card-head has-background-kin">
          <span class="modal-card-title has-text-light">Odwołaj wizytę</span>
        </header>
        <section
          class="modal-card-body"
          style="color: black;"
        >
          <div class="pb-2">
            <p class="subtitle is-centered mb-5">Powód odwołania wizyty</p>
            <b-field
              position="is-centered"
              v-for="item in appointmentCancelReasons"
              :key="item.id"
            >
              <b-radio
                v-model="cancelAppointmentReason"
                :native-value="item.id"
                :disabled="item.id == 0"
              >{{item.name}}</b-radio>
            </b-field>
          </div>
        </section>
        <footer class="modal-card-foot has-background-kin buttons is-right pt-2 pb-1">
          <b-button
            :loading="requestInProgress"
            @click="isCancelAppointmentModalActive = false"
          >Anuluj</b-button>
          <b-button
            :disabled="readonly || block.appointment === null || cancelAppointmentReason === null"
            :loading="requestInProgress"
            @click="cancelAppointment"
            type="is-danger"
          >Odwołaj wizytę</b-button>
        </footer>
      </div>
    </b-modal>

    <AppointmentCancelReferralP1
      @eReferral-canceled="cancelAppointmentEreferralCancelled"
      :trigger="triggerCancelReferral"
      :ticket="block.appointment.ticketInfo"
      :referral="null"
    />

    <RISWorkshopAddUsgDetails
      v-if="perms.radiologyRegistration && isRisUSG && block.appointment.state < 1 && isToday"
      :trigger="triggerSendExam"
      :appointment="block.appointment"
      :ticket="block.appointment.ticketInfo"
      :deviceId="block.appointment.device"
      @send-usg="sendUsgEvent"
    />

  </div>
</template>

<script>
import CommonMixins from "@/mixins/commons";
import EwusMixins from "@/mixins/ewus";
import DateHelpers from "@/mixins/date_helpers";
import AppMixin from "../../mixins/appointments";
import moment from "moment";
import { Action } from "../../store/config_actions";
import { Mutation } from "../../store/config_mutations";
import ClinicsMixins from "@/mixins/clinics";
import MedEnumsMixins from "@/mixins/med_enums";
import AppointmentsMixins from "@/mixins/appointments";
import InvMixins from "@/mixins/inventory";
import MobiMixins from "@/mixins/mobi"
import RisMixins from "@/mixins/ris"
import PersonnelMixin from "@/mixins/personnel";
import NfzContracts from "@/mixins/finance/nfzContracts";
import SmsGateModal from '@/components/sms/SmsGateModal'
import PhoneVerification from "@/components/sms/PhoneVerification"
import SmsPatientHistory from "@/components/sms/SmsPatientHistory"

// import ContentPanel from "@/components/tools/ContentPanel";

// import DocsHeadlineSubunit from "@/components/tools/DocsHeadlineSubunit";
import AppointmentCancelReferralP1 from '@/components/appointments/AppointmentCancelReferralP1'

import SchedulePatientChangeOrder from '@/components/ris/ris-workshops/patients/SchedulePatientChangeOrderVer2'
import SchedulePatientChangeIKZ from '@/components/ris/ris-workshops/patients/SchedulePatientChangeIKZ'
import RISPatientChangeAPKolce from '@/components/ris/ris-workshops/patients/RISPatientChangeAPKolce'
import RISPatientChangeAPKolceReferral from '@/components/ris/ris-workshops/patients/RISPatientChangeAPKolceReferral'
import RISPatientChangeDILO from '@/components/ris/ris-workshops/patients/RISPatientChangeDILO'

import RISWorkshopAddUsgDetails from "@/components/ris/ris-workshops/RISWorkshopAddUsgDetails"

import CollectionPointExamChange from "@/components/collection-point/CollectionPointExamChange"


import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue"

import IkzRegistrationMixins from '@/mixins/finance/ikz_registration'

export default {
  name: "Schedule2AppDetails",
  mixins: [CommonMixins, DateHelpers, AppMixin, ClinicsMixins, MedEnumsMixins, AppointmentsMixins,
    InvMixins, MobiMixins, RisMixins, PersonnelMixin, IkzRegistrationMixins, EwusMixins, NfzContracts],
  components: {
    AppointmentCancelReferralP1,
    SchedulePatientChangeOrder,
    SchedulePatientChangeIKZ,
    RISPatientChangeAPKolce,
    RISPatientChangeAPKolceReferral,
    RISPatientChangeDILO,
    RISWorkshopAddUsgDetails,
    CollectionPointExamChange,
    //  ContentPanel,
    SmsGateModal,
    PhoneVerification,
    SmsPatientHistory,

    VueTimepicker,
  },

  data: function () {
    return {
      appointment: null,
      appointmentObject: null,
      appointmentObjectTmp: null,
      currentOffice: null,
      subcategories: null,
      visitLengthMin: 5,
      // patient: null,
      employee: null,
      employeeCreated: null,
      employeeEdit: null,
      employeeWorktime: null,
      requestInProgress: false,
      editmode: false,
      appCopy: JSON.parse(JSON.stringify(this.block.appointment)),

      //print props
      subunit: null,
      facility: null,
      room: null,
      loadingSubunit: false,
      loading: false,

      duration: 0,
      maxDurationEditedVisit: 5,
      editTrigger: 0,
      groupPatients: [],

      isCancelAppointmentModalActive: false,
      cancelAppointmentReason: null,
      triggerCancel: 0,
      triggerCancelReferral: 0,

      comment: "",

      employeeFilter: "",
      facilityFilter: "",
      vehicleReservation: null,

      fileProps: null,

      filteredAppTypes: [],
      showAllAppTypes: false,
      patient: null,

      triggerSendExam: 0,

      timeObject: { HH: "12", mm: "00" },
      enabledHours: [],
      enabledMinutes: [],

      appStartDate: moment(this.block.start).toDate(),
    };
  },

  watch: {
    appStartDate() {
      this.maxDurationEditedVisit = this.getMaxDuration()
    },

    "timeObject.HH": function () {
      this.updateTimesInControl()
    },

    "timeObject.mm": function () {
      this.setupStartDate()
    },

    editmode() {
      if (this.editmode) {

        this.defUnselectable()

        let startMoment = moment(this.appCopy.start)
        this.timeObject = {
          HH: startMoment.format("HH"),
          mm: startMoment.format("mm"),
        }

        this.updateTimesInControl()
        this.maxDurationEditedVisit = this.getMaxDuration()

        if (this.appCopy.isTimeless) {
          // 11.10.2023
          // Timelesss apps edited through app details modal don't have block.start prop, only "appointment" object
          // So appStartDate state prop must be set to appointment start date, otherwise it will be default value of moment library - which is present date
          this.appStartDate = moment(this.block.appointment.start).toDate(),
            this.duration = moment(this.appCopy.stop).diff(moment(this.appCopy.start), "minutes")
        } else {
          this.duration = moment(this.appCopy.stop).diff(moment(this.appCopy.start), "minutes")
        }

        if (this.isVehicle) {
          let detailsObj = JSON.parse(this.block.appointment.description)
          this.vehicleReservation = detailsObj

          this.employeeFilter = this.getEmployee(this.appCopy.employee)
          if (this.vehicleReservation.reservationDetails.internalClinic) {
            this.facilityFilter = this.getFacilityNameByInternalId(this.vehicleReservation.reservationDetails.destinationClinic)
          }
        }
      }
    },

    "appCopy.payerType": function () {
      if (this.appCopy.payerType === 0) {
        this.appCopy.isPrivate = true
      } else {
        this.appCopy.isPrivate = false
        this.appCopy.isPaid = false
        this.appCopy.paymentId = null
      }
    },

    showAllAppTypes() {
      this.myAppointmentTypes();
    },

  },

  async mounted() {

    if (this.block && this.block.appointment && this.block.appointment.patient) {
      this.patient = this.block.appointment.patient
      this.patient.age = moment().diff(moment(this.patient.dateOfBirth), "years");
    }

    this.appointment = this.reset();
    await this.getFullInfo();
    this.getFullInfoCreated();
    this.getFullInfoWorktime();
    this.getSubunit();
    this.getPatients();
    this.myAppointmentTypes();
    await this.getTicket();
  },

  props: {
    block: { type: Object, required: true },
    blocks: { type: Array, required: false },
    readonly: { type: Boolean, required: false },
    // patient: {
    //   validator: (prop) => typeof prop === "object" || prop === null,
    //   required: true,
    // },
  },

  computed: {

    allMedServices() {
      return this.$store.state.finance.ikz
    },

    ageFrom() {
      if (this.appointmentObject) {
        return this.appointmentObject.ageFrom
      }
      else {
        return 0
      }
    },

    ageTo() {
      if (this.appointmentObject) {
        return this.appointmentObject.ageTo
      }
      else {
        return 999
      }
    },

    ageRestriction() {
      if (this.patient && this.appointmentObject) {
        if (this.appointmentObject.hasAgeRestriction && (this.patient.age < this.ageFrom || this.patient.age > this.ageTo)) {
          return true
        }
        else {
          return false
        }
      }
      else {
        return false
      }
    },

    registrationPatient() {
      return this.$store.state.registration.patient
    },

    permits() { return this.$store.state.employee.permits },

    canShorterAppointment() {
      if (this.permits && this.permits.shorterAppointments) return true;
      else return false;
    },

    minDurationEditedVisit() {
      let minDuration = 5;
      if (this.appointmentObject) {
        if (!this.canShorterAppointment && this.appointmentObject.minTime !== null && this.appointmentObject.minTime !== undefined && this.appointmentObject.blockTime) {
          minDuration = this.appointmentObject.minTime
        }
      }
      return minDuration;
    },

    minDurationEditedVisitInfo() {
      let minDuration = 5;
      if (this.appointmentObject) {
        if (this.appointmentObject.minTime !== null && this.appointmentObject.minTime !== undefined && (this.appointmentObject.blockTime === false || this.canShorterAppointment)) {
          minDuration = this.appointmentObject.minTime
        }
      }
      return minDuration;
    },

    previousBlock() {
      if (this.blocks) {
        let index = this.blocks.indexOf(this.block)
        if (index != -1) {
          let prevBlock = this.blocks[index - 1]

          if (prevBlock) {
            return prevBlock
          }
        }
      }

      return null
    },

    nextBlock() {
      if (this.blocks) {
        let index = this.blocks.indexOf(this.block)
        if (index != -1) {
          let nextBlock = this.blocks[index + 1]

          if (nextBlock) {
            return nextBlock
          }
        }
      }

      return null
    },


    previousBlockStart() {
      if (this.previousBlock) {
        if (this.previousBlock.appointment) {
          return moment(this.block.start)
        } else {
          return moment(this.previousBlock.start)
        }
      }

      return moment(this.block.start)
    },

    nextBlockEnd() {
      if (this.nextBlock) {
        if (this.nextBlock.appointment) {
          return moment(this.block.end)
        } else {
          return moment(this.nextBlock.end)
        }
      }

      return moment(this.block.end)
    },

    minMoment() {
      return this.previousBlockStart
    },

    maxMoment() {
      return this.nextBlockEnd
    },

    maxMomentOption() {
      return moment(this.nextBlockEnd).subtract(3, "minutes")
    },

    me() {
      return this.$store.state.employee.me;
    },

    meParentFacilityObject() {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.internalId === this.me.parentFacility);
        return found;
      } else return null;
    },

    registrationIkz() {
      return this.$store.state.registration.ikz
    },

    apps() {
      return this.$store.state.config.appointments;
    },

    vehicleApps() {
      return this.apps.filter(option => option.isVehicle === true)
    },

    focusBlock() {
      return this.$store.state.poz.focusBlock;
    },

    isHistory() {
      let now = moment().startOf("day");
      return moment(this.block.appointment.start).isBefore(now);
    },

    isToday() {
      return this.mDate(moment()) === this.mDate(this.block.appointment.start)
    },

    facilities() {
      return this.$store.state.clinic.clinics ?? [];
    },

    facilitiesFiltered() {
      return this.facilities.filter((option) => {
        if (option.name) {
          return (
            option.name
              .toLowerCase()
              .indexOf(this.facilityFilter.toLowerCase()) >= 0
          );
        } else return false;
      });
    },

    isVehicle() {
      return this.block.worktimeArea && this.block.worktimeArea.vehicle > 0
    },

    isRISOffice() {
      if (this.focusOffice) {
        return this.mTypesRiSClinics.includes(this.focusOffice.clinicId);
      } else if (this.block && this.block.worktimeArea && this.block.worktimeArea.clinic) {
        return this.mTypesRiSClinics.includes(this.block.worktimeArea.clinic);
      } else if (this.block && this.block.appointment && this.block.appointment.clinic) {
        return this.mTypesRiSClinics.includes(this.block.appointment.clinic);
      } else return false;
    },

    thisOfficeType() {
      let officeId = null
      let off = null

      if (this.allOffices && this.block.worktimeArea && this.block.worktimeArea.officeId) {
        officeId = this.block.worktimeArea.officeId
      } else if (this.allOffices && this.block.appointment && this.block.appointment.officeId) {
        officeId = this.block.appointment.officeId
      }

      if (officeId) {
        off = this.allOffices.find(x => x.id == officeId)
      }

      if (off) {
        let offtype = this.configOffices.find(r => r.id == off.type)
        return offtype
      }

      return null
    },

    isOfficeScheduleBlocked() {
      let officeId = null
      let off = null

      if (this.allOffices && this.block.worktimeArea && this.block.worktimeArea.officeId) {
        officeId = this.block.worktimeArea.officeId
      } else if (this.allOffices && this.block.appointment && this.block.appointment.officeId) {
        officeId = this.block.appointment.officeId
      }

      if (officeId) {
        off = this.allOffices.find(x => x.id == officeId)
      }

      if (off) {
        return off.isScheduleBlocked
      }

      return false
    },


    isOfficeIpom() {
      if (this.thisOfficeType) {
        return this.thisOfficeType.id == 101000
      } else return false
    },

    isRisUSG() {
      if (this.thisOfficeType) {
        return this.thisOfficeType.id == 100400
      } else return false
    },

    isCollectionPointOffice() {
      if (this.thisOfficeType) {
        return this.thisOfficeType.id == 100100
      } else return false
    },

    docs() { return this.$store.state.employee.all },

    vehicles() {
      return this.$store.state.mobi.mobiVehicles
    },

    vehicleReservationDetails() {
      let detailsObj = JSON.parse(this.block.appointment.description)
      return detailsObj.reservationDetails
    },

    elmpoyeeFiltered() {
      return this.docs.filter(option => {
        let id = (option.lastName + " " + option.firstName).toLowerCase()
        if (this.employeeFilter != null) {
          return id.indexOf(this.employeeFilter.toLowerCase()) >= 0
        }
        else return false
      })
    },

    configOffices() {
      return this.$store.state.config.offices;
    },

    allOffices() {
      return this.$store.state.offices.offices;
    },

    configAreas() {
      return this.$store.state.config.areas;
    },

    perms() {
      return this.$store.state.employee.permits
    },

    phoneNumberWarning() {
      if (this.patient && this.patient.teleAddressTelephoneNumber) {
        let fragment = this.patient.teleAddressTelephoneNumber.slice(0, 3)
        if (fragment == '800' || fragment == '801') {
          return true
        }

        if (this.patient.teleAddressTelephoneNumber.length !== 9) {
          return true
        }
      }

      return false
    },

    phoneNumberPrefixWarning() {
      if (this.patient && this.patient.teleAddressTelephoneNumber && this.patient.teleAddressTelephonePrefix) {
        if (this.patient.teleAddressTelephoneNumber.length > 9) {
          let fragment = this.patient.teleAddressTelephoneNumber.slice(0, 2)
          if (fragment == '48' && (this.patient.teleAddressTelephonePrefix == "+48" || this.patient.teleAddressTelephonePrefix == "48")) {
            return true
          }
        }
      }

      return false
    },

    printLoading() { return this.$store.state.config.printLoading }
  },

  methods: {

    getDeklPersonel(id) {

      if (id < 1) {
        return "Brak"
      }
      else if (this.docs) {
        let doc = this.docs.find(x => x.id == id)

        if (doc) {
          return doc.firstName + " " + doc.lastName
        }
        else {
          return `Brak danych (ID: ${id})`
        }
      }
      else {
        return 'Brak danych'
      }
    },

    getMedService(id) {
      if (id && this.allMedServices) {
        let med = this.allMedServices.find(x => x.id == id)

        if (med) {
          if (med.bum) {
            return `${med.bum} - ${med.name}`
          }
          else {
            return med.name
          }
        }
        else {
          return ''
        }
      }
      else {
        return ''
      }
    },

    completeReservation() {
      this.$emit('complete-reservation', this.block)
    },

    addSms(item) {
      if (this.block && this.block.appointment) {
        if (this.block.appointment.sms) {
          this.block.appointment.sms.push(item)
        }
        else {
          this.block.appointment.sms = []
          this.block.appointment.sms.push(item)
        }
      }
    },

    getPatients() {
      if (this.block.appointment.isGroup) {
        this.$store.dispatch(Action.PATIENT_GET_MANY, this.block.appointment.groupPatients)
          .then((x) => {
            this.groupPatients = x.data
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    addToGroup() {
      this.$buefy.dialog.confirm({
        message: 'Na pewno dołączyć pacjenta ' + this.registrationPatient.lastName + ' ' + this.registrationPatient.firstName + ' do spotkania grupowego?',
        hasIcon: true,
        type: 'is-info',
        scroll: 'keep',
        confirmText: 'Tak, dołącz',
        cancelText: 'Anuluj',
        onConfirm: () => {
          this.loading = true
          var payload = { id: this.block.appointment.id, patientId: this.registrationPatient.id }
          this.$store
            .dispatch(Action.S3_ADD_TO_GROUP, payload)
            .then((data) => {
              this.block.appointment.groupPatients = data.groupPatients
              this.loading = false
              this.$emit('close')
              this.$emit("created", data);
            })
            .catch(error => {
              this.apiProblem(error);
              this.loading = false
            });
        }
      })
    },

    removePatientFromGroup(pat) {
      this.$buefy.dialog.confirm({
        message: 'Na pewno usunąć pacjenta ' + pat.lastName + ' ' + pat.firstName + ' ze spotkania grupowego?',
        hasIcon: true,
        type: 'is-danger',
        scroll: 'keep',
        confirmText: 'Tak, usuń',
        cancelText: 'Anuluj',
        onConfirm: () => {
          this.loading = true
          var payload = { id: this.block.appointment.id, patientId: pat.id }
          this.$store
            .dispatch(Action.S3_REMOVE_FROM_GROUP, payload)
            .then((data) => {
              this.block.appointment.groupPatients = data.groupPatients
              this.loading = false
              this.$emit('close')
            })
            .catch(error => {
              this.apiProblem(error);
              this.loading = false
            });
        }
      })
    },

    getAppWays(appType) {
      let ways = []
      var apNone = { id: -1, name: 'Brak konfiguracji' }
      var ap0 = { id: 0, name: 'Wizyta w przychodni', icon: 'home-city-outline' }
      var ap1 = { id: 1, name: 'Teleporada', icon: 'phone-in-talk-outline' }
      var ap2 = { id: 2, name: 'Wizyta domowa', icon: 'car-outline' }

      if (appType || appType === 0) {

        let configured = this.apps.find(x => x.id == appType)

        if (this.appointmentObject) {
          configured = this.appointmentObject
        }

        if (configured && configured.ways) {
          configured.ways.forEach(w => {
            if (w === 0) ways.push(ap0)
            if (w === 1) ways.push(ap1)
            if (w === 2) ways.push(ap2)
          })

          if (ways.length < 1) {
            ways.push(apNone)
          }
        }
        else {
          ways.push(apNone)
        }
      }
      else {
        ways.push(apNone)
      }

      return ways
    },

    getAppPayers(appType) {
      let payers = []
      var apNone = { id: -1, name: 'Brak płatników' }
      var ap0 = { id: 0, name: 'Komercja' }
      var ap1 = { id: 1, name: 'NFZ' }
      var ap2 = { id: 2, name: 'MZ' }

      if (appType || appType === 0) {
        let configured = this.apps.find(x => x.id == appType)

        if (this.appointmentObject) {
          configured = this.appointmentObject
        }

        if (configured && configured.payers) {
          configured.payers.forEach(p => {
            if (p === 0) payers.push(ap0)
            if (p === 1) payers.push(ap1)
            if (p === 2) payers.push(ap2)
          })

          if (payers.length < 1) {
            payers.push(apNone)
          }
        }
        else {
          payers.push(apNone)
        }
      }
      else {
        payers.push(apNone)
      }

      return payers.sort((a, b) => b.id - a.id)
    },

    reschedule() {
      this.$store.commit(Mutation.APPOINTMENT_SIMPLE_FOCUS, this.block);
      this.$emit("reschedule-ordered");
      this.$emit("close");
      this.toast("Wybierz nowy termin na terminarzu")
    },

    getAppName(id) {
      let category = this.apps.find((c) => c.id == id);
      if (category) return category.name;
      else return `Nieznana strefa`;
    },

    getAppSubCategory(id, sid) {
      if (sid === null || sid < 0) return 'Brak'

      let category = this.apps.find(c => c.id == id)

      if (category) {
        if (category.subcategories) {
          let subcategory = category.subcategories.find(s => s.id == sid)
          if (subcategory) {
            return subcategory.name
          }
        }
      }

      return `??? [${sid}]`
    },


    getAppIcon(id) {
      let category = this.apps.find((c) => c.id == id);
      if (category) return category.icon;
      else return `help`;
    },

    async getFullInfo() {
      if (this.block.appointment.employee < 1) {
        this.employee = { firstName: '', lastName: 'Brak', id: -1 }
        this.myAppointmentTypes()
      } else {
        await this.$store
          .dispatch(Action.EMPLOYEE_GET, this.block.appointment.employee)
          .then((x) => {
            this.employee = x.data;
            this.myAppointmentTypes()
          })
          .catch((error) => {
            this.employee = { firstName: '', lastName: 'Brak', id: -1 }
            this.myAppointmentTypes()
            this.apiProblem(error);
          });
      }

      // this.$store.dispatch(Action.PATIENT_GET_BY_ID, this.block.appointment.patientId)
      //   .then((x) => {
      //     this.patient = x.data
      //   })
      //   .catch((error) => {
      //     this.apiProblem(error)
      //   })
    },

    getFullInfoCreated() {
      if (this.block.appointment.createdBy < 1) {
        this.employeeCreated = { firstName: "", lastName: "Brak", id: -1 };
      } else {
        this.$store
          .dispatch(Action.EMPLOYEE_GET, this.block.appointment.createdBy)
          .then((x) => {
            this.employeeCreated = x.data;
          })
          .catch((error) => {
            this.employeeCreated = { firstName: "", lastName: "Brak", id: -1 };
            this.apiProblem(error);
          });
      }

      if (this.block.appointment.modifiedBy == 0) {
        this.employeeEdit = { firstName: "", lastName: "Brak", id: 0 };
      }
      else if (this.block.appointment.modifiedBy == -8) {
        this.employeeEdit = { firstName: "", lastName: "Weryfikacja EWUŚ", id: -8 };
      }
      if (this.block.appointment.modifiedBy < 1) {
        this.employeeEdit = { firstName: "", lastName: "Automat systemu SZOK", id: this.block.appointment.modifiedBy };
      }
      else {
        this.$store
          .dispatch(Action.EMPLOYEE_GET, this.block.appointment.modifiedBy)
          .then((x) => {
            this.employeeEdit = x.data;
          })
          .catch((error) => {
            this.employeeEdit = { firstName: "", lastName: "Brak", id: -1 };
            this.apiProblem(error);
          });
      }
    },

    getFullInfoWorktime() {
      if (this.block.worktimeArea && this.block.worktimeArea.employee > -1) {
        if (this.block.worktimeArea.employee === this.block.appointment.employee) {
          this.employeeWorktime = this.employee
        } else {
          this.$store
            .dispatch(Action.EMPLOYEE_GET, this.block.appointment.createdBy)
            .then((x) => {
              this.employeeWorktime = x.data;
            })
            .catch((error) => {
              this.apiProblem(error);
            });
        }
      }
    },

    saveUpdates() {
      this.editmode = false
      this.appCopy.type = this.appointmentObjectTmp

      this.appCopy.start = moment(this.appStartDate).format("YYYY-MM-DDTHH:mm:00")
      this.appCopy.stop = moment(this.appStartDate).add(this.duration, "minutes").format("YYYY-MM-DDTHH:mm:00")

      this.$buefy.dialog.confirm({
        message: "Czy na pewno zmienić dane wizyty?",
        confirmText: "Tak",
        cancelText: "Anuluj",
        scroll: "keep",
        hasIcon: true,
        type: "is-info",
        onConfirm: () => {
          this.requestInProgress = true;
          this.$store
            .dispatch(Action.S2_EDIT_APPOINTMENT, this.appCopy)
            .then(() => {

              //ADD IKZ - EDYCJA WIZYTY
              this.addIKZVisitEdit()

              this.successSnackbar("Zapisano")
              this.requestInProgress = false
              this.block.appointment = this.appCopy
              this.$emit("edited")
            })
            .catch((error) => {
              this.requestInProgress = false
              this.apiProblem(error)
            });
        },
      });
    },

    markForReschedule() {
      this.editmode = false;
      this.$buefy.dialog.confirm({
        message: "Czy na pewno oznaczyć do przełożenia?",
        confirmText: "Tak",
        cancelText: "Anuluj",
        scroll: "keep",
        hasIcon: true,
        type: "is-info",
        onConfirm: () => {
          this.appCopy.isForReschedule = true;
          this.requestInProgress = true;
          this.$store
            .dispatch(Action.S2_EDIT_APPOINTMENT, this.appCopy)
            .then(() => {
              this.successSnackbar("Zapisano");
              this.$emit("removed", this.block.appointment);
              this.requestInProgress = false;
            })
            .catch((error) => {
              this.requestInProgress = false;
              this.apiProblem(error);
            });
        },
      });
    },

    splitGroup() {
      this.$buefy.dialog.confirm({
        message: "Czy na pewno rozdzielić wizytę na mniejsze dla każdego pacjenta? Zostanie utworzone <strong>" + this.block.appointment.groupPatients.length + " nowych wizyt</strong> w miejsce obecnej grupowej.",
        scroll: "keep",
        hasIcon: true,
        confirmText: 'Tak, rozdziel',
        cancelText: 'Anuluj',
        type: "is-danger",
        onConfirm: () => {
          this.requestInProgress = true;
          this.$store
            .dispatch(Action.S3_SPLIT_GROUP, this.block.appointment.id)
            .then(() => {
              this.$emit("split");
              this.requestInProgress = false;
            })
            .catch((error) => {
              this.requestInProgress = false;
              this.apiProblem(error);
            });
        },
      });
    },

    onSubTypeChanged(val) {
      this.switchSubWithType(val)
    },

    switchSubWithType(val) {
      if (this.subcategories && this.subcategories.length > 0) {
        let candidate = this.subcategories.find(x => x.id == val)

        if (candidate) {
          this.appointmentObject = candidate
          this.appCopy.subtype = candidate.id;
        }
      }
    },

    onTypeChanged(val) {
      let appObject = this.filteredAppTypes.find(x => x.id == val)
      if (appObject) {
        this.appointmentObject = appObject
        if (appObject.subcategories) {
          this.subcategories = appObject.subcategories;

          if (this.subcategories && this.subcategories.length > 0) {
            this.appCopy.subtype = this.subcategories[0].id;
            this.appointmentObject = this.subcategories[0]
          } else {
            this.appCopy.subtype = -1;
            this.subcategories = null;
          }
        } else {
          this.appCopy.subtype = -1;
          this.subcategories = null;
        }
      }

    },

    reset() {
      return {
        state: 0,
        subject: 0,
        spec: 0,
        patientId: 0,
        start: this.block.start,
        stop: moment(this.block.start)
          .add(5, "minutes")
          .format("YYYY-MM-DDTHH:mm:00"),
        area: 0,
        plannedProcedures: [],
        type: 0,
        subtype: 0,
        service: 0,
        description: "",
        reason: "",
        isUrgent: false,
        isPrivate: false,
        isTimeless: false,
        destination: 0,
        externalId: 0,
        created: this.mNow(),
        createdBy: this.me ? this.me.id : -1,
        modified: this.mNow(),
        modifiedBy: this.me ? this.me.id : -1,
      };
    },

    //print function
    getDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },

    getHour(date) {
      return moment(date).format("HH:mm");
    },

    //printMethords
    print() {
      if (this.$refs["printDetails"]) {
        let config = {
          "htmlContent": this.$refs["printDetails"].innerHTML,
          "landscape": false,
          "pageSize": -1,
          "customWidth": 105,
          "customHeight": 295,
          "useCustomMargin": true,
          "customMarginTop": 0,
          "customMarginBottom": 10,
          "customMarginLeft": 5,
          "customMarginRight": 5,
          "optionalHeaderFromSecondPage": null,
          "printHtmlBackgrounds": false,
          "createPdfFormsFromHtml": false
        }
        this.mPrintBackendWithConfig(config)
        this.addIkzPrintDetails()
      } else {
        this.dangerSnackbar("Błąd wydruku")
      }
    },

    getSubunit() {
      if (this.block.appointment.officeId) {
        this.loadingSubunit = true;
        this.$store
          .dispatch(
            Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_OFFICE_ID,
            this.block.appointment.officeId
          )
          .then((response) => {
            this.subunit = response;
            if (response && response.facilityId) {
              this.facility = this.getFacility(response.facilityId);
            } else {
              this.facility = null;
            }
            this.loadingSubunit = false;
          })
          .catch((error) => {
            this.subunit = null;
            this.facility = null;
            this.loadingSubunit = false;
            this.apiProblem(error);
          });
      } else if (this.block.appointment.subunitId) {
        this.loadingSubunit = true;
        this.$store
          .dispatch(
            Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_ID,
            this.block.appointment.subunitId
          )
          .then((response) => {
            this.subunit = response;
            if (response && response.facilityId) {
              this.facility = this.getFacility(response.facilityId);
            } else {
              this.facility = null;
            }
            this.loadingSubunit = false;
          })
          .catch((error) => {
            this.subunit = null;
            this.facility = null;
            this.loadingSubunit = false;
            this.apiProblem(error);
          });
      } else if (this.block.appointment.facility > 0) {
        this.facility = this.getFacilityByInternalId(this.block.appointment.facility);
      } else {
        this.facility = null;
      }

      if (this.block.appointment.officeRoomId) {
        this.$store
          .dispatch(Action.INVENTORY_GET_ROOM, this.block.appointment.officeRoomId)
          .then((data) => {
            if (data) {
              data.nameFull = `Nr ${data.number ?? "?"}, ${this.mGetName(
                this.mRoomTypes,
                data.type
              )} ${data.name ?? "-"}, ${data.description}`;
            }
            this.room = data;
          })
          .catch((error) => {
            this.room = null;
            this.apiProblem(error);
          });
      } else {
        this.room = null;
      }
    },

    getFacility(id) {
      if (this.facilities) {
        let pos = this.facilities.find((s) => s.id === id);
        if (pos) return pos;
        else return null;
      } else return null;
    },

    getFacilityByInternalId(id) {
      if (this.facilities) {
        let pos = this.facilities.find((s) => s.internalId === id);
        if (pos) return pos;
        else return null;
      } else return null;
    },

    getFacilityNameByInternalId(id) {
      if (this.facilities) {
        let pos = this.facilities.find((s) => s.internalId === id);
        if (pos) return pos.name;
        else return 'Brak informacji o placówce: ' + id;
      } else return 'Brak informacji o placówkach';
    },

    getEmployee(id) {
      let emp = this.docs.find(x => x.id === id)
      if (emp) return `${emp.lastName} ${emp.firstName}`
      else return `Brak danych`
    },

    getVehicle(id) {
      let vehicle = this.vehicles.find(x => x.id === id)
      if (vehicle) return `${vehicle.brand} ${vehicle.model} \nRejestracja: ${vehicle.registrationNumber}`
      else return `Brak danych`
    },

    async getTicket() {
      if (this.block.appointment.ticket) {
        this.requestInProgress = true;
        await this.$store.dispatch(Action.TICKET_GET, this.block.appointment.ticket)
          .then(async (resp) => {
            if (resp) {
              this.block.appointment.ticketInfo = resp

              if (this.block.appointment.plannedProcedures && this.block.appointment.plannedProcedures.length > 0) {
                let ticketProcedure = resp.procedures[this.block.appointment.plannedProcedures[0]] ?? null;

                if (ticketProcedure !== null && ticketProcedure !== undefined) {
                  this.block.appointment.procedure = ticketProcedure;
                  this.block.appointment.procedureId = this.block.appointment.plannedProcedures[0];
                } else {
                  this.block.appointment.procedure = resp.procedures[0]
                  this.block.appointment.procedureId = 0
                }
              } else {
                if (resp && resp.procedures.length > 0) {
                  this.block.appointment.procedure = resp.procedures[0];
                  this.block.appointment.procedureId = 0;
                } else {
                  this.block.appointment.procedure = null;
                }
              }
            }

            let responseP1Referral = null
            if (resp.referralResults && resp.referralResults.eSkierowanieKlucz) {
              await this.$store.dispatch(Action.RESULTS_P1_REFERRAL_GET_BY_KLUCZ, { domain: this.me.domainId, patientId: this.patient.id, klucz: resp.referralResults.eSkierowanieKlucz })
                .then((response) => {
                  responseP1Referral = response
                })
                .catch((error) => {
                  this.apiProblem(error)
                })
            } else if (resp.referralResults && resp.referralResults && resp.referralResults.resultsId) {
              await this.$store.dispatch(Action.RESULTS_P1_REFERRAL_GET_BY_ID, resp.referralResults.resultsId)
                .then((response) => {
                  responseP1Referral = response
                })
                .catch((error) => {
                  this.apiProblem(error)
                })
            }

            if (responseP1Referral) {
              this.block.appointment.ticketInfo.referralResultsObject = responseP1Referral
              let responseP1HTML = null

              await this.$store.dispatch(Action.RESULTS_P1_REFERRAL_HTML_DOWNLOAD, responseP1Referral.id)
                .then((response) => {
                  responseP1HTML = response.fileHtml ?? null
                })
                .catch((error) => {
                  this.apiProblem(error)
                })

              if (responseP1HTML) {
                this.block.appointment.ticketInfo.referralResultsObject.fileHtml = responseP1HTML
              }
            }

            this.requestInProgress = false;
          })
          .catch((error) => {
            this.apiProblem(error)
            this.requestInProgress = false;
          })
      }
    },

    cancelAppointment() {

      if (
        this.block.appointment.ticketInfo
        && this.block.appointment.ticketInfo.referral
        && this.block.appointment.ticketInfo.referral.canceledId == null
        && this.block.appointment.ticketInfo.referral.acceptedId != null
        && this.block.appointment.ticketInfo.referral.eReferral
        && this.block.appointment.ticketInfo.referral.eReferral.id != null) {
        this.successSnackbar('Anulowanie skierowania')
        this.triggerCancelReferral += 1
      } else if (
        this.block.appointment.ticketInfo
        && this.block.appointment.ticketInfo.referralResultsObject
        && this.block.appointment.ticketInfo.referralResultsObject.esk
        && this.block.appointment.ticketInfo.referralResultsObject.esk.cancelledId == null
        && this.block.appointment.ticketInfo.referralResultsObject.esk.acceptedId != null
      ) {
        this.successSnackbar('Anulowanie skierowania')
        this.triggerCancelReferral += 1
      } else {
        this.cancelAppointmentNoReferral()
      }
    },

    cancelAppointmentEreferralCancelled() {
      this.addIkzReferralCancel()
      this.cancelAppointmentNoReferral()
    },

    cancelAppointmentNoReferral() {
      if (this.patient) {
        this.cancelAppointmentNoReferralNextStep()
      }
      else if (this.block.appointment.patientId > 0) {
        this.$store
          .dispatch(Action.PATIENT_GET_BY_ID, this.block.appointment.patientId)
          .then((response) => {
            this.patient = response.data
            this.cancelAppointmentNoReferralNextStep()
          })
          .catch(error => {
            this.apiProblem(error);
          });
      }
    },

    cancelAppointmentNoReferralNextStep() {
      if (this.patient) {
        this.requestInProgress = true
        // let obj = {
        //   version: this.patient.version,
        //   stats: {
        //     patientId: this.patient.id,
        //     appointmentsAttended: this.patient.appointmentsAttended,
        //     appointmentsMissed: this.patient.appointmentsMissed,
        //     appointmentsCancelled: this.patient.appointmentsCancelled,
        //     appointmentsLate: this.patient.appointmentsLate,
        //     appointmentsRescheduled: this.patient.appointmentsRescheduled
        //   }
        // }
        let payload = {
          id: this.block.appointment.id,
          reason: this.cancelAppointmentReason
        }

        this.$store.dispatch(Action.S2_CANCEL_APPOINTMENT, payload)
          .then(() => {
            this.requestInProgress = false
            this.successSnackbar('Odwołano wizytę')
            this.isCancelAppointmentModalActive = false
            this.$emit("removed", this.block.appointment);

            //ADD IKZ - ODWOŁANIE WIZYTY
            this.addIkzAppointmentCancel()

            if (this.isCollectionPointOffice && this.block.appointment.ticketInfo) {
              if (this.block.appointment.type === 94) {
                this.setTicketCancelled(this.block.appointment.ticketInfo)
              } else {
                this.setTicketFree(this.block.appointment.ticketInfo)
              }
            }

            if (this.block.appointment.ticketInfo && this.block.appointment.ticketInfo.programId) {
              this.setTicketFree(this.block.appointment.ticketInfo)
            }


            // this.$store.dispatch(Action.PATIENT_UPDATE_STATS, obj)
            //   .then(() => {
            //       this.$emit('close')
            //       this.requestInProgress = false

            //   })
            //   .catch((error) => {
            //     this.apiProblem(error)
            //     this.requestInProgress = false
            //   })
          })
          .catch((error) => {
            this.apiProblem(error)
            this.requestInProgress = false
          })
      } else if (this.block.appointment.isGroup) {
        let payload = {
          id: this.block.appointment.id,
          reason: this.cancelAppointmentReason
        }

        this.$store.dispatch(Action.S2_CANCEL_APPOINTMENT, payload)
          .then(() => {
            this.successSnackbar('Odwołano wizytę')
            this.$emit("removed", this.block.appointment);
            this.isCancelAppointmentModalActive = false
          })
          .catch((error) => {
            this.apiProblem(error)
            this.requestInProgress = false
          })
      }
      else {
        this.snackbar("Wystąpił problem z danymi. Prosimy o ponowne pobranie terminarza.")
      }
    },

    setTicketFree(ticket) {
      this.requestInProgress = true
      if (ticket) {
        this.$store.dispatch(Action.TICKET_SET_STATUS, { id: ticket.id, status: 1 })
          .then(() => {
            this.requestInProgress = false
            this.successSnackbar('Uwolniono bilet')
            this.$store.commit(Mutation.REGISTRATION_SET_TICKET_STATUS, { id: ticket.id, state: 1 })
            this.$store.commit(Mutation.PATIENT_SET_TICKET_STATUS, { id: ticket.id, state: 1 })

            //ADD IKZ - UWOLNIENIE BILETU
            this.addIkzTicketAvailable()
          })
          .catch((error) => {
            this.requestInProgress = false
            this.apiProblem(error)
          })
      }
    },

    setTicketCancelled(ticket) {
      this.requestInProgress = true
      if (ticket) {
        this.$store.dispatch(Action.TICKET_SET_STATUS, { id: ticket.id, status: 5 })
          .then(() => {
            this.requestInProgress = false
            this.successSnackbar('Anulowano bilet')
            this.$store.commit(Mutation.REGISTRATION_SET_TICKET_STATUS, { id: ticket.id, state: 5 })
            this.$store.commit(Mutation.PATIENT_SET_TICKET_STATUS, { id: ticket.id, state: 5 })
          })
          .catch((error) => {
            this.requestInProgress = false
            this.apiProblem(error)
          })
      }
    },

    cancelAppointmentVehicle() {
      this.$store.dispatch(Action.S2_DELETE_APPOINTMENT, this.block.appointment.id)
        .then(() => {
          this.successSnackbar('Odwołano rezerwację')
          this.$emit("removed");
        })
        .catch((error) => {
          this.apiProblem(error)
          this.requestInProgress = false
        })
    },

    saveUpdatesVehicle() {
      this.editmode = false
      this.appCopy.stop = moment(new Date(this.appCopy.start)).add(this.duration, "minutes").format("YYYY-MM-DDTHH:mm:00")
      this.$buefy.dialog.confirm({
        message: "Czy na pewno zmienić dane wizyty?",
        confirmText: "Tak",
        cancelText: "Anuluj",
        scroll: "keep",
        hasIcon: true,
        type: "is-info",
        onConfirm: () => {

          this.requestInProgress = true;
          this.appCopy.description = JSON.stringify({ reservationDetails: this.vehicleReservation.reservationDetails })
          this.$store
            .dispatch(Action.S2_EDIT_APPOINTMENT_VEHICLE, this.appCopy)
            .then(() => {
              this.successSnackbar("Zapisano")
              this.requestInProgress = false
              this.block.appointment = this.appCopy
              this.$emit("edited")
            })
            .catch((error) => {
              this.requestInProgress = false
              this.apiProblem(error)
            });
        },
      });
    },

    getDownloadedFile(fileId) {
      this.requestInProgress = true;
      //this.currentBlob = null;
      this.fileProps = {
        FileNameId: fileId,
        WorkerId: this.me.id,
        PatientId: this.patient.id,
      };
      this.$store
        .dispatch(Action.PATIENT_DOWNLOAD_FILE, this.fileProps)
        .then((response) => {
          //this.fileOptions(option, currentFile);
          let url = URL.createObjectURL(response.data);
          window.open(url, "_blank", "location=yes,scrollbars=yes");
          URL.revokeObjectURL(url);

          this.requestInProgress = false;
        })
        .catch((error) => {
          this.requestInProgress = false;
          this.dangerSnackbar(error.toString());
          //this.apiProblem(error);
        });
    },

    getOffice(id) {
      let category = this.allOffices.find((c) => c.id == id);
      if (category) return category.name;
      else return `Brak informacji`;
    },
    getOfficeObject(id) {
      return this.allOffices.find((c) => c.id == id);
    },

    getOfficeTypeApps(id) {
      let category = this.configOffices.find((c) => c.id == id);
      if (category && category.appointments) return category.appointments;
      else return [];
    },

    myAppointmentTypes() {
      let types = [];

      if (this.block && this.block.worktimeArea && this.block.worktimeArea.officeId) {
        this.currentOffice = this.getOfficeObject(this.block.worktimeArea.officeId)
      }

      if (this.block.worktimeArea && this.block.worktimeArea.device) {
        if (this.isRISOffice) {
          let office = this.getOfficeObject(this.block.worktimeArea.officeId);

          if (office.type > -1) {
            let possibles = this.getOfficeTypeApps(office.type);

            let filtered = this.apps.filter((a) => {
              return possibles.includes(a.id);
            });

            filtered.forEach((el) => {
              if (types.includes(el)) {
                // ok
              } else {
                types.push(el);
              }
            });
          }
        } else {
          types.push({ id: 0, name: "Ogólna" });
        }
      }
      else if (this.block.appointment && this.block.appointment.officeId && this.block.appointment.device) {
        if (this.isRISOffice) {
          let office = this.getOfficeObject(this.block.appointment.officeId);
          if (office.type > -1) {
            let possibles = this.getOfficeTypeApps(office.type);

            let filtered = this.apps.filter((a) => {
              return possibles.includes(a.id);
            });

            filtered.forEach((el) => {
              if (types.includes(el)) {
                // ok
              } else {
                types.push(el);
              }
            });
          }
        } else {
          types.push({ id: 0, name: "Ogólna RIS" });
        }
      }
      else if (
        this.block.worktimeArea &&
        (this.block.worktimeArea.officeId === null ||
          (this.block.worktimeArea.employee > -1 && this.showAllAppTypes))
      ) {
        if (this.employee && this.employee.roles) {
          this.employee.roles.forEach((pos) => {
            let posObject = this.mGetRoleObject(pos);
            if (posObject) {
              let possibles = posObject.appointments;
              let filtered = this.apps.filter((a) => {
                return possibles.includes(a.id);
              });
              filtered.forEach((el) => {
                if (types.includes(el)) {
                  // ok
                } else {
                  types.push(el);
                }
              });
            }
          });
        }
      } else if (
        this.block.worktimeArea &&
        this.block.worktimeArea.employee > -1 &&
        this.showAllAppTypes
      ) {
        if (this.employeeWorktime && this.employeeWorktime.roles) {
          this.employeeWorktime.roles.forEach((pos) => {
            let posObject = this.mGetRoleObject(pos);
            if (posObject) {
              let possibles = posObject.appointments;
              let filtered = this.apps.filter((a) => {
                return possibles.includes(a.id);
              });
              filtered.forEach((el) => {
                if (types.includes(el)) {
                  // ok
                } else {
                  types.push(el);
                }
              });
            }
          });
        }
      } else if (
        (this.block.worktimeArea === null || this.block.worktimeArea === undefined) &&
        (this.block.appointment.officeId == null || (this.block.appointment.employee > -1 && this.showAllAppTypes))
      ) {
        if (this.employee && this.employee.roles) {
          this.employee.roles.forEach((pos) => {
            let posObject = this.mGetRoleObject(pos);
            if (posObject) {
              let possibles = posObject.appointments;
              let filtered = this.apps.filter((a) => {
                return possibles.includes(a.id);
              });
              filtered.forEach((el) => {
                if (types.includes(el)) {
                  // ok
                } else {
                  types.push(el);
                }
              });
            }
          });
        }
      } else if (this.block.worktimeArea) {
        //let area = this.getOfficeAreaObject(this.block.worktimeArea.office)
        let office = this.getOfficeObject(this.block.worktimeArea.officeId);
        if (office.type > -1) {
          let possibles = this.getOfficeTypeApps(office.type);
          let filtered = this.apps.filter((a) => {
            return possibles.includes(a.id);
          });

          filtered.forEach((el) => {
            if (types.includes(el)) {
              // ok
            } else {
              types.push(el);
            }
          });
        }
      } else if (this.block.appointment.officeId) {
        //let area = this.getOfficeAreaObject(this.block.worktimeArea.office)
        let office = this.getOfficeObject(this.block.appointment.officeId);
        if (office && office.type > -1) {
          this.focusOffice = office;
          let possibles = this.getOfficeTypeApps(office.type);
          let filtered = this.apps.filter((a) => {
            return possibles.includes(a.id);
          });

          filtered.forEach((el) => {
            if (types.includes(el)) {
              // ok
            } else {
              types.push(el);
            }
          });
        }
      }

      this.filteredAppTypes = types;

      if (this.block.appointment.type > -1 && this.filteredAppTypes.length > 0) {
        let appType = this.filteredAppTypes.find((x) => x.id === this.block.appointment.type)

        if (appType) {
          this.appointmentObject = appType
        }

        if (this.appointmentObject) {
          this.appointmentObjectTmp = this.appointmentObject.id
          this.onTypeChanged(this.appointmentObject.id);

          /**
           * Looks stupid, but sets correct subtype for appointment edit
           * 
           * Correct subtype - same as in block.appointment.subtype prop
           * Needed because this.onTypeChanged(this.appointmentObject.id) selects the first possible subtype for type
           * not the correct subtype (if exists)
           */
          if (this.subcategories && this.subcategories.length > 0 && this.block.appointment.subtype > -1) {
            this.appCopy.subtype = this.block.appointment.subtype
            let subcategoryObject = this.subcategories.find(x => x.id === this.block.appointment.subtype)
            if (subcategoryObject) {
              this.appointmentObject = subcategoryObject
            }
          }
          /*************************************************** */
          /*************************************************** */
        }
      }
      else if (this.block.appointment.area > -1 && this.filteredAppTypes.length > 0) {
        var workConfig = this.configAreas.find(x => x.id === this.block.appointment.area)

        if (workConfig) {
          var defApp = this.filteredAppTypes.find(a => a.id === workConfig.defaultAppointment)

          if (defApp) {
            this.appointmentObject = defApp;
          }
          else {
            this.appointmentObject = this.filteredAppTypes[0];
          }
        }
        else {
          this.appointmentObject = this.filteredAppTypes[0];
        }

        this.appointmentObjectTmp = this.appointmentObject.id
        this.onTypeChanged(this.appointmentObject.id);
      }
    },

    printEReferral() {
      const options = {
        styles: [
          'http://localhost:8080/printStylesEReferral.css',
          'http://t1.digitmed.pl/printStylesEReferral.css',
        ]
      }
      this.$htmlToPaper("printEReferral", options)
      this.addIkzPrintEreferral()
    },

    editmodeCancel() {
      this.editmode = false
    },

    sendUsgEvent() {
      this.$emit('send-usg')
    },

    addIKZVisitEdit() {
      let selected = this.registrationIkz.find(x => x.internalId == 525)
      this.addIkz(selected)
    },

    addIKZVisitEditCancel() {
      let selected = this.registrationIkz.find(x => x.internalId == 617)
      this.addIkz(selected)
    },

    addIkzPrintDetails() {
      let selected = this.registrationIkz.find(x => x.internalId == 606)
      this.addIkz(selected)
    },

    addIkzPrintEreferral() {
      let selected = this.registrationIkz.find(x => x.internalId == 610)
      this.addIkz(selected)
    },

    addIkzReferralCancel() {
      let selected = this.registrationIkz.find(x => x.internalId == 599)
      this.addIkz(selected)
    },

    addIkzAppointmentCancel() {
      let selected = this.registrationIkz.find(x => x.internalId == 483)
      this.addIkz(selected)
    },

    changeOrderUpdate() {
      this.addIkzChangeOrder()
      this.getTicket()
    },

    addIkzChangeOrder() {
      let selected = this.registrationIkz.find(x => x.internalId == 488)
      this.addIkz(selected)
    },

    changeIKZUpdate() {
      this.addIkzChangeIkz()
      this.getTicket()
    },

    addIkzChangeIkz() {
      let selected = this.registrationIkz.find(x => x.internalId == 489)
      this.addIkz(selected)
    },

    changeAPKolceUpdate() {
      this.addIkzChangeAPKolce()
      this.getTicket()
    },

    addIkzChangeAPKolce() {
      let selected = this.registrationIkz.find(x => x.internalId == 490)
      this.addIkz(selected)
    },

    changeAPKolceReferralUpdate() {
      this.addIkzChangeAPKolceReferral()
      this.getTicket()
    },

    addIkzChangeAPKolceReferral() {
      let selected = this.registrationIkz.find(x => x.internalId == 491)
      this.addIkz(selected)
    },

    changeDILOUpdate() {
      this.addIkzDILOUpdate()
      this.getTicket()
    },

    addIkzDILOUpdate() {
      let selected = this.registrationIkz.find(x => x.internalId == 492)
      this.addIkz(selected)
    },

    addIkzTicketAvailable() {
      let selected = this.registrationIkz.find(x => x.internalId == 631)
      this.addIkz(selected)
    },

    addIkz(selected) {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient ? this.patient.id : 0,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },


    /*
      <!--------------------------------------------------------->
      <!--******************* APPOINTMENT TIME METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    updateTimesInControl() {
      let minH = this.minMoment.hours()
      let minM = this.minMoment.minutes()
      let maxH = this.maxMoment.hours()
      let maxM = this.maxMoment.minutes()

      let hour = parseInt(this.timeObject.HH)

      let mS = 0
      let xS = 59

      if (hour === minH) {
        mS = minM
        //this.timeObject.mm = this.minMoment.format('mm')
      }

      if (hour === maxH) {
        xS = maxM
      }

      this.enabledMinutes.length = 0 // = []
      for (let g = mS; g < xS; g++) {
        this.enabledMinutes.push(g)
      }

      if (this.enabledMinutes.length > 0) {
        let pM = parseInt(this.timeObject.mm)
        let findEnabledmm = this.enabledMinutes.find(min => min === pM)
        if (findEnabledmm) {
          this.timeObject = {
            HH: this.timeObject.HH,
            mm: this.timeObject.mm,
          }
        } else {
          this.timeObject = {
            HH: this.timeObject.HH,
            mm: this.enabledMinutes[0].toString().padStart(2, "0"),
          }
        }
      }

      this.setupStartDate()
    },

    setupStartDate() {
      let pH = parseInt(this.timeObject.HH)
      let pM = parseInt(this.timeObject.mm)

      this.appStartDate.setHours(pH)
      this.appStartDate.setMinutes(pM)

      this.maxDurationEditedVisit = this.getMaxDuration()

      // 11.10.2023
      // !this.appCopy.isTimeless - added because we don't have blocks info for timeless visits so maxDurationEditedVisit gives wrong value
      if (this.duration > this.maxDurationEditedVisit && !this.appCopy.isTimeless) {
        this.duration = this.maxDurationEditedVisit
      }
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */

    /*
      <!--------------------------------------------------------->
      <!--******************* APPOINTMENT MAX LENGTH METHODS ******************* -->
      <!--------------------------------------------------------->
    */

    getMaxDuration() {
      return moment(this.nextBlockEnd).diff(moment(this.appStartDate), "minutes")
    },

    /*
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
      <!--------------------------------------------------------->
    */

    defUnselectable() {
      this.enabledHours.length = 0 // = []

      let tmp = moment(this.previousBlockStart).add(-1, 'minutes')

      while (tmp.isBefore(this.maxMomentOption)) {
        tmp.add(1, "minutes")

        if (tmp.minutes() % 5 > 0) {
          // no push
        } else {
          this.enabledHours.push(tmp.hours())
        }
      }
    },

    async changeCollectionPointExamsUpdate(appointmentUpdate) {
      await this.getTicket()

      this.appCopy.procedures = appointmentUpdate.procedures
      this.block.appointment.procedures = appointmentUpdate.procedures
      this.appCopy.plannedProcedures = appointmentUpdate.plannedProcedures
      this.block.appointment.plannedProcedures = appointmentUpdate.plannedProcedures

      this.appCopy.version = appointmentUpdate.version
      this.block.appointment.version = appointmentUpdate.version
    },

    emitEditPatientData() {
      this.$emit("close")
      this.$emit("show-patient-data", this.patient)
    },

    patientPhoneValidated(response) {
      this.patient.teleAddressTelephoneConfirmed = response.validated
      this.patient.version = response.version

      if (this.registrationPatient) {
        if (this.patient.id === this.registrationPatient.id) {
          this.$store.commit(Mutation.REGISTRATION_SET_PATIENT_TELEPHONE_VERIFIED, response)
        }
      }
    }
  },
};
</script>

<style scoped lang="scss">
</style>