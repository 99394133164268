<template>
  <div>
    <div class="columns is-variable is-0 is-desktop">
      <div class="column pt-3 is-narrow" style="z-index: 2;">
        <div v-if="focusBlock">
          <p class="mb-2">
            <b-field expanded>
              <p class="control is-expanded">
                <b-button icon-left="clock-outline" expanded>Przekładanie wizyty</b-button>
              </p>
              <p class="control">
                <b-button type="is-danger" icon-left="close" @click="cancelReschedule">Anuluj</b-button>
              </p>
            </b-field>
          </p>
          <ScheduleBlockAppointment
            v-if="focusBlock"
            :block="focusBlock"
            :date="momentDate(focusBlock.date)"
            style="max-height: 3rem; overflow: hidden"
            class="mb-5"
          ></ScheduleBlockAppointment>
        </div>
        <b-field v-if="perms && perms.worktimeScheduler && false">
          <b-button
            @click="openWorktimeScheduler"
            icon-left="calendar-weekend"
            size="is-small"
            expanded
            class="mr-1"
          >Konfiguracja stref</b-button>
        </b-field>
        <div>
          <b-field expanded>
            <p class="control">
              <b-button size="is-small" type="is-static">Tryb</b-button>
            </p>
            <b-select size="is-small" expanded v-model="scheduleViewType">
              <option :value="0">Wybrane dni</option>
              <option :value="1">Tydzień od</option>
            </b-select>
          </b-field>
        </div>
        <b-field class="mb-3 mt-3">
          <b-datepicker
            class="my-borderless-calendar"
            v-model="datesSelected"
            :month-names="monthNames"
            :day-names="dayNamesShort"
            :first-day-of-week="1"
            placeholder="Wybierz"
            custom-class="hand"
            icon="calendar-month"
            :multiple="scheduleViewType === 0"
            ref="datesPicker"
            :mobile-native="false"
            :key="scheduleViewType"
            expanded
            inline
            size="is-small"
          >
            <b-field>
              <b-button
                size="is-small"
                icon-left="calendar-month"
                class="mr-1"
                @click="cleanDates(true)"
              >Dzisiaj</b-button>
              <b-button
                size="is-small"
                type="is-danger"
                icon-left="close"
                class="mr-1"
                outlined
                v-if="scheduleViewType === 0"
                @click="cleanDates(false)"
              >Wyczyść</b-button>
            </b-field>
          </b-datepicker>
        </b-field>

        <b-button
          :loading="requestInProgress"
          @click="getSchedule0"
          type="is-primary"
          icon-right="calendar"
          expanded
          :disabled="filters2.employee === -1"
        >Pobierz terminarz</b-button>

        <!-- <code>{{ filters2.facilities }}</code> -->
        <!--<hr />
        <hr />-->
        <!-- <pre>{{datesSelected}}</pre> -->
        <!-- <pre>{{scheduleViewType}}</pre> -->
        <!-- <pre>{{focusBlock}}</pre> -->
        <!-- <pre>app:{{focusAppointment}}</pre> -->
      </div>
      <div class="column pl-3" ref="scheduleColumn" style="max-width:calc(100% - 18rem);">
        <Schedule
          :trigger="trigger"
          :resetTrigger="resetTrigger"
          :filters="filters2"
          :patient="patient"
          :rescheduleMode="focusBlock != null"
          :scheduleViewType="scheduleViewType"
          :personal="personal"
          @created="onAppointmentCreated"
          @remove="onAppointmentRemove"
          @update="onAppointmentUpdate"
          @response="requestInProgress = false"
          @error="requestInProgress = false"
          @search-patient="searchPatientEvent"
          @scroll-days="changeDays"
        ></Schedule>
      </div>
    </div>
    <b-modal
      :active.sync="isWorktimeSchedulerModalActive"
      has-modal-card
      full-screen
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      class="chatsize"
      scroll="keep"
    >
      <div class="modal-card">
        <header class="modal-card-head has-background-kin" style="border-radius: 0px !important">
          <p class="modal-card-title has-text-light is-size-5">
            <b-icon icon="calendar-weekend" class="mr-3" type="is-light"></b-icon>Konfiguracja stref terminarza
          </p>
        </header>
        <section class="modal-card-body">
          <WorktimeScheduler />
        </section>
        <footer class="modal-card-foot has-background-kin buttons is-right">
          <b-button
            type="is-primary"
            @click="isWorktimeSchedulerModalActive = false"
            icon-left="close"
            size="is-small"
          >Zamknij</b-button>
        </footer>
      </div>
    </b-modal>

    <div v-if="!personal">
      <RegistrationDispatcher />
    </div>
  </div>
</template>

<script>
import CommonMixins from '@/mixins/commons'
import PersonnelMixins from '@/mixins/personnel'
import AppoMixins from '@/mixins/appointments'
import ClinicsMixins from '@/mixins/clinics'
import SpecMixins from '@/mixins/specializations'
import MedEnums from '@/mixins/med_enums'
import DateHelpers from '@/mixins/date_helpers'
import moment from 'moment'
import { Action } from '@/store/config_actions'
import Schedule from '@/components/schedule2/Schedule2'
import { Mutation } from '@/store/config_mutations'
import WorktimeScheduler from '@/components/schedule/wrappers/WorktimeScheduler'
import ScheduleBlockAppointment from '@/components/schedule/ScheduleBlockAppointment'
import { Cookies } from '@/store/config_cookies'

import RegistrationDispatcher from '@/components/registration/RegistrationDispatcher'

export default {
  name: 'MySchedulePanel',
  mixins: [CommonMixins, DateHelpers, MedEnums, SpecMixins, ClinicsMixins, PersonnelMixins, AppoMixins],

  components: {
    Schedule,
    WorktimeScheduler,
    ScheduleBlockAppointment,
    RegistrationDispatcher,
  },

  props: {
    resetTrigger: { type: Number, required: false },
    personal: { type: Boolean, required: false, default: true },
  },

  watch: {

    multipleMode(val) {
      this.$cookies.set(Cookies.SCHEDULE_MULTICHOICE_REG, val);
      this.multiOffices = []
    },

    selectedCluster(value) {
      if (value) {
        var facs = []


        // Filter facility IDs
        this.facilities.forEach(f => {
          if (f.clusterId === value.internalId) {
            facs.push(f.internalId)
          }
        })

        this.filters2.facilities = facs
      }
      else {
        // All clusters included
        this.filters2.facilities = null
      }
    },

    resetTrigger() {
      this.scheduleMode = 0
      this.resetFilters()
    },

    searchMode(val) {
      this.resetFilters()
      this.$nextTick(() => {
        switch (val) {
          case "0":
            if (this.$refs.auto0) this.$refs.auto0.focus()
            break
          case "1":
            if (this.$refs.auto1) this.$refs.auto1.focus()
            break
          case "2":
            if (this.$refs.auto2) this.$refs.auto2.focus()
            break
        }
      })
    },

    activeFacility() {
      this.getSchedule()
    },

    scheduleViewType() {
      if (this.scheduleViewType === 0) {
        this.datesSelected = []
      }
      else if (this.scheduleViewType === 1) {
        //this.datesSelected = null
        this.cleanDates(true)
      }
      else if (this.scheduleViewType === 2) {
        this.datesSelected = null
      }
    },

    allRooms(val) {
      if (!val) {
        this.filters2.room = null
      }
    },

    datesSelected() {
      this.refactorDates()

      if (this.scheduleMode == 0) {
        //this.getSchedule()
      }
    },

    scheduleMode() {
      this.resetFilters()
    },

    selectedFacility() {
      this.multiOffice = []
      this.wrapGeneralMenu()
    },

    employeeSlots() {
      if (this.employeeSlots && this.employeeSlots.length > 0 && !this.currentFacility) {
        this.employeeSlots.forEach(element => {
          let el = this.facilities.find(f => f.id == element.facilityId)
          if (el) {
            this.selectedFacility = el.name
          }
        })
      }
    },

    currentFacility() {
      if (this.currentFacility) {
        this.selectedFacility = this.currentFacility.name
      }
    },

    editVisitTrigger() {
      if (this.searchMode == "0") {
        this.getSchedule3()
      } else if (this.searchMode == "1") {
        this.getSchedule9()
      } else if (this.searchMode == "2") {
        this.getSchedule1()
      }
    },

    allOffices: {
      deep: true,
      handler(valNew, valOld) {
        if ((valOld == null || (valOld != null && valOld.length == 0)) && valNew != null && valNew.length > 0) {
          this.wrapGeneralMenu();
        }
      }
    }
  },

  data: function () {
    return {
      searchMode: "1",
      scheduleMode2: 0,
      generalMenu: [],
      scheduleMode: 0,
      vehicles: [],
      vehiclesVisible: false,
      multipleMode: true,
      onlyAvailable: false,
      multiOffices: [],
      // filters: {
      //   dates: [],
      //   subjectId: -2,
      //   patientId: null,
      //   subject: null,
      //   spec: null,
      //   type: null,
      //   subtype: null,
      //   service: null,
      //   timeless: null,
      //   private: null
      // },

      // filters2: {
      //   "searchType": 0,
      //   "dates": [],
      //   "subjectId": null,
      //   "clinicId": null,
      //   "spec": null,
      //   "area": null
      // },

      filters2: {
        "searchType": 0,
        "dates": [
        ],
        "facilities": [],
        "employee": -1,
        "device": null,
        "clinic": -1,
        "spec": -1,
        "area": -1,
        "office": null,
        "multiOffice": []
      },

      selectedCluster: null,

      employee: null,
      requestInProgress: false,
      scheduleViewType: 1,
      datesSelected: new Date(),
      docFilter: '',
      clinicFilter: '',
      officeFilter: '',
      roomFilter: '',
      trigger: 0,
      clearTrigger: 0,
      medicalDevices: [],
      offices: [],
      rooms: [],
      deviceFilter: '',
      device: null,
      selectedArea: -1,
      columnWidth: 500,
      includeRoom: false,
      allRooms: false,
      currentOffice: null,

      isWorktimeSchedulerModalActive: false,

      selectedFacility: null,
      facilityName: "",

      employeeSlots: null,
      vehicleSelected: null
    }
  },

  mounted() {

    this.employee = this.me
    this.filters2.employee = this.me.id

    let multiCookie = this.$cookies.get(Cookies.SCHEDULE_MULTICHOICE_REG);

    if (multiCookie != undefined && multiCookie != null) {
      this.multipleMode = multiCookie == "true"
    }
    else {
      // do nothing
    }


    this.getEmployeeSlots()
    this.downloadMedicalDevices()
    this.downloadVehicles()
    this.downloadRooms()

    this.selectedCluster = null// this.clusters.length > 0 ? this.clusters[0] : null

    this.datesSelected = new Date()

    let moments = []
    let thisMoment = moment(this.datesSelected)
    moments.push(thisMoment)

    for (let index = 1; index < 7; index++) {
      const momentAdded = moment(thisMoment).add(index, 'days');
      moments.push(momentAdded)
    }

    for (let index = 0; index < moments.length; index++) {
      const element = moments[index];

      if (element.format('ddd') !== 'Sun') {
        let local = element.format('YYYY-MM-DD')
        this.filters2.dates.push(local)
      }
    }

    if (this.currentFacility) {
      this.selectedFacility = this.currentFacility.name
      this.facilityName = this.currentFacility.name
    }

    this.wrapGeneralMenu()

    if(!this.personal) {
      this.$store.commit(Mutation.SCHEDULE_SET_PATIENT_VISITS, [])
      this.getSchedule0()
      this.resetEwus()
    }
  },

  computed: {

    configOffices() { return this.$store.state.config.offices },
    clusters() { return this.$store.state.config.clusters },
    clinics() { return this.$store.state.config.clinics },
    //facilities() { return this.$store.state.config.facilities },
    facilities() {

      let allfacs = this.$store.state.config.facilities

      //if (this.allOffices) {
      if (this.allOffices) {
        let ids = []
        this.allOffices.forEach(x => {
          if (x.facilityId && !ids.includes(x.facilityId)) {
            ids.push(x.facilityId)
          }
        })

        let selected = []

        ids.forEach(x => {
          let alfc = allfacs.find(e => e.id == x)

          if (alfc) {
            selected.push(alfc)
          }
        })

        return selected
      }
      else return allfacs
    },

    allOffices() { return this.$store.state.offices.offices },

    activeFacility() { return this.$store.state.clinic.activeClinic },
    patient() { return this.$store.state.registration.patient },
    perms() { return this.$store.state.employee.permits },
    me() { return this.$store.state.employee.me },
    workers() { return this.$store.state.employee.all },

    docs() { return this.$store.state.employee.all ?? [] },

    elmpoyeeFiltered() {
      return this.docs.filter(option => {
        let id = (option.lastName + option.firstName).toLowerCase()
        if (this.docFilter != null) {
          return id.indexOf(this.docFilter.toLowerCase()) >= 0
        }
        else return false
      })
    },

    clinicAvailables() {
      if (this.availableOffices) {
        let ids = []
        this.availableOffices.forEach(x => {
          if (x.clinicId && !ids.includes(x.clinicId)) {
            ids.push(x.clinicId)
          }
        })

        let selected = []

        ids.forEach(x => {
          let alClin = this.clinics.find(e => e.id == x)

          if (alClin) {
            selected.push(alClin)
          }
        })

        return selected.sort(this.sortById)
      } else return this.clinics
    },

    clinicFiltered() {
      return this.clinicAvailables.filter(option => {
        let id = option.name.toLowerCase()
        return (
          id.indexOf(this.clinicFilter.toLowerCase()) >= 0
        )
      })
    },

    availableOffices() {
      if (this.allOffices) {
        return this.allOffices.filter(option => {
          return option.hasScheduleDisabled == false
        })
      } else return [];
    },

    officesFiltered() {
      return this.availableOffices.filter(option => {
        let id = ''

        if (option.pharse) {
          id = option.pharse.toLowerCase()
        }
        else {
          id = option.name.toLowerCase()
        }

        return (
          id.indexOf(this.officeFilter.toLowerCase()) >= 0
        )
      })
    },

    roomsFiltered() {
      return this.roomsOptions.filter(option => {
        let id = option.name.toLowerCase()
        return (
          id.indexOf(this.roomFilter.toLowerCase()) >= 0
        )
      })
    },

    roomsOptions() {
      if (this.filters2.office) {
        let array = this.rooms

        if (this.currentOffice && this.currentOffice.facilityId) {
          if (!this.allRooms) {
            array = this.rooms.filter(r => r.facilityId === this.currentOffice.facilityId)
          }

          return array
        }
        else return array
      }
      else return []
    },

    devicesFiltered() {
      return this.medicalDevices.filter(option => {
        let id = option.name.toLowerCase()
        return (
          id.indexOf(this.deviceFilter.toLowerCase()) >= 0
        )
      })
    },

    focusAppointment() {
      return this.$store.state.poz.focusAppointment
    },
    focusBlock() {
      return this.$store.state.poz.focusBlock
    },

    currentFacility() {
      return this.$store.state.config.currentFacility
    },

    editVisitTrigger() {
      return this.$store.state.registration.editVisitTrigger
    },

    filteredFacilities() {
      if (this.facilities) {
        return this.facilities.filter((option) => {
          let result = false;
          if (option.name) {
            result = option.name
              .toString()
              .toLowerCase()
              .indexOf(this.facilityName.toLowerCase()) >= 0
          }
          if (!result && option.address) {
            if (option.address.street) {
              result = option.address.street
                .toString()
                .toLowerCase()
                .indexOf(this.facilityName.toLowerCase()) >= 0
            }

            if (!result && option.address.city) {
              result = option.address.city
                .toString()
                .toLowerCase()
                .indexOf(this.facilityName.toLowerCase()) >= 0
            }
          }
          if (!result && option.name) {
            result = option.name
              .toString()
              .toLowerCase()
              .indexOf(this.facilityName.toLowerCase()) >= 0
          }

          return (result)
        });
      }

      return [];
    }
  },

  methods: {

    getClinicName(id) {
      if (id < 0) return 'Brak poradni'
      let category = this.clinics.find(c => c.id == id)
      if (category) return category.name
      else return `${id} - Poradnia nieokreślona`
    },

    getOfficeName(id) {
      if (id < 0) return 'Brak typu'
      let category = this.configOffices.find(c => c.id == id)
      if (category) return category.name
      else return `Typ nieokreślony (${id})`
    },

    getEmployeeName(id) {
      if (id < 0) return 'Brak pracownika'
      let category = this.workers.find(c => c.id == id)
      if (category) return `${category.firstName} ${category.lastName}`
      else return `Pracownik nieokreślony (${id})`
    },

    changeDays(val) {
      if (this.scheduleViewType != 0) {
        var result = this.datesSelected.setDate(this.datesSelected.getDate() + val);
        this.datesSelected = new Date(result);

        if (moment(this.datesSelected).format('ddd') === 'Sun') {
          let skipSunday = val > 0 ? 1 : -1;
          result = this.datesSelected.setDate(this.datesSelected.getDate() + skipSunday);
        }
        this.datesSelected = new Date(result);

        this.trigger++
      } else {
        //do nothing, change on calendar :P
      }
    },


    toggleVehicles() {
      this.vehiclesVisible = !this.vehiclesVisible

      if (this.vehiclesVisible) {
        this.scheduleViewType = 1
      }
    },

    searchPatientEvent(payload) {
      this.$emit('search-patient', payload)
    },

    momentDate(item) {
      return moment(item)
    },

    cancelReschedule() {
      this.$store.commit(Mutation.APPOINTMENT_SIMPLE_FOCUS, null)
    },

    wrapGeneralMenuOLD() {
      let clins = []
      let facilityId = null

      if (this.selectedFacility) {
        let facility = this.facilities.find(f => f.name == this.selectedFacility)
        if (facility) {
          facilityId = facility.id
        }
      }

      this.allOffices.forEach(office => {
        if (facilityId) {
          //
          if (office.clinicId > -1 && office.facilityId == facilityId && !office.hasScheduleDisabled) {
            let existing = clins.find(c => c.id === office.clinicId)

            if (existing) {
              // cool
            }
            else {
              existing = { id: office.clinicId, types: [] }
              clins.push(existing)
            }

            let extype = existing.types.find(e => e.id === office.type)

            if (extype) {
              extype.offices.push(office)
            }
            else {
              extype = { id: office.type, offices: [] }
              extype.offices.push(office)
              existing.types.push(extype)
            }
          }



        } else {
          if (office.clinicId > -1 && !office.hasScheduleDisabled) {
            let existing = clins.find(c => c.id === office.clinicId)

            if (existing) {
              // cool
            }
            else {
              existing = { id: office.clinicId, types: [] }
              clins.push(existing)
            }

            let extype = existing.types.find(e => e.id === office.type)

            if (extype) {
              extype.offices.push(office)
            }
            else {
              extype = { id: office.type, offices: [] }
              extype.offices.push(office)
              existing.types.push(extype)
            }
          }
        }
      })

      this.generalMenu = clins.sort(this.sortById)
    },

    wrapGeneralMenu() {
      let clins = []
      let facilityId = null

      if (this.selectedFacility) {
        let facility = this.facilities.find(f => f.name == this.selectedFacility)
        if (facility) {
          facilityId = facility.id
        }
      }

      this.allOffices.forEach(office => {
        if (facilityId) {
          //
          if (office.clinicId > -1 && office.facilityId == facilityId && !office.hasScheduleDisabled) {
            let existing = clins.find(c => c.id === office.clinicId)

            if (existing) {
              // cool
            }
            else {
              existing = { id: office.clinicId, offices: [] }
              clins.push(existing)
            }

            existing.offices.push(office)
          }
        } else {
          if (office.clinicId > -1 && !office.hasScheduleDisabled) {
            let existing = clins.find(c => c.id === office.clinicId)

            if (existing) {
              // cool
            }
            else {
              existing = { id: office.clinicId, offices: [] }
              clins.push(existing)
            }

            existing.offices.push(office)
          }
        }
      })

      this.generalMenu = clins.sort(this.sortById)
    },

    sortById(a, b) {
      return a.id - b.id;
    },

    resetFilters() {
      this.filters2 = {
        "searchType": 0,
        "dates": this.filters2.dates,
        "facilities": this.filters2.facilities,
        "employee": -1,
        "device": null,
        "clinic": -1,
        "spec": -1,
        "area": -1,
        "office": null,
        "vehicle": -1,
        "multiOffice": []
      }

      this.clinicFilter = ''
      this.officeFilter = ''
      this.docFilter = ''
    },

    downloadOffices() {
      this.loading = true
      this.$store
        .dispatch(Action.OFFICE_GET_ALL)
        .then((data) => {
          this.offices = data
          this.loading = false
        })
        .catch(error => {
          this.apiProblem(error);
          this.loading = false
        });

    },

    downloadRooms() {
      this.$store
        .dispatch(Action.OFFICEROOM_GET_ALL)
        .then((response) => {
          this.rooms = response
        })
        .catch(error => {
          this.apiProblem(error);
        });
    },

    downloadMedicalDevices() {
      this.requestInProgress = true
      this.$store
        .dispatch(Action.MEDICAL_DEVICE_GET_ALL)
        .then((data) => {
          this.medicalDevices = data
          this.requestInProgress = false
        })
        .catch(error => {
          this.apiProblem(error);
          this.requestInProgress = false
        });
    },

    downloadVehicles() {
      this.requestInProgress = true
      this.$store
        .dispatch(Action.MOBI2_GET_VEHICLES)
        .then((data) => {
          data.forEach(x => {
            x.name = `${x.registrationNumber} ${x.brand} ${x.model}`
          })
          this.vehicles = data
          this.requestInProgress = false
        })
        .catch(error => {
          this.apiProblem(error);
          this.requestInProgress = false
        });
    },

    getItem(id) {
      let category = this.configOffices.find(c => c.id == id)
      if (category) return category.name
      else return `???`
    },

    cleanDates(resetToToday) {
      while (this.filters2.dates.length > 0) this.filters2.dates.pop()

      if (resetToToday) {
        this.scheduleViewType = 1
        this.$nextTick(() => {
          this.datesSelected = new Date()
        })
      }
      else {
        this.scheduleViewType++
        this.$nextTick(() => { this.scheduleViewType-- })
      }
    },


    refactorDates() {
      this.filters2.dates = []

      if (this.datesSelected) {
        switch (this.scheduleViewType) {
          case 0: {
            this.datesSelected.forEach(d => {
              this.filters2.dates.push(moment(d).format('YYYY-MM-DD'))
            })
            break
          }
          case 1: {
            let moments = []
            let thisMoment = moment(this.datesSelected)
            moments.push(thisMoment)

            for (let index = 1; index < 7; index++) {
              const momentAdded = moment(thisMoment).add(index, 'days');
              moments.push(momentAdded)
            }

            for (let index = 0; index < moments.length; index++) {
              const element = moments[index];

              if (element.format('ddd') !== 'Sun') {
                let local = element.format('YYYY-MM-DD')
                this.filters2.dates.push(local)
              }
            }
            break
          }
        }
      }
    },

    onAppointmentCreated(payload) {
      this.$emit('created', payload)
    },

    onAppointmentUpdate(payload) {
      this.$emit('update', payload)
    },

    onAppointmentRemove(payload) {
      this.$emit('remove', payload)
    },


    getSchedule() {

      if (this.$refs.scheduleColumn) {
        this.columnWidth = this.$refs.scheduleColumn.clientWidth
      }

      this.filters2.onlyAvailable = this.onlyAvailable

      this.requestInProgress = true
      this.trigger++
    },

    getSchedule0() {
      this.filters2.searchType = 0
      this.getSchedule()
    },

    getSchedule1() {
      this.filters2.searchType = 1
      this.filters2.area = this.selectedArea
      this.getSchedule()
    },

    getSchedule2() {
      this.filters2.searchType = 2
      this.getSchedule()
    },

    getSchedule20() {
      this.filters2.employee = -1
      this.filters2.spec = -1
      this.filters2.area = -1
      this.filters2.clinic = -1
      this.filters2.searchType = 20

      if (this.vehicleSelected) {
        this.filters2.vehicle = this.vehicleSelected.id
        this.getSchedule()
      }
    },

    getSchedule3() {
      this.filters2.searchType = 3
      this.getSchedule()
    },

    getSchedule8() {

      if (this.includeRoom) {
        this.filters2.searchType = 4
      }
      else {
        this.filters2.searchType = 8
      }

      if (this.currentOffice && this.currentOffice.employee > -1) {
        this.filters2.employee = this.currentOffice.employee
      }

      this.getSchedule()
    },

    onMenuOfficeClick(office) {

      if (this.multipleMode) {

        this.filters2.multiOffice = []
        this.multiOffices.forEach(x => this.filters2.multiOffice.push(x.id))

        if (this.scheduleViewType == 1) {
          this.getSchedule11()
        }
        else {
          this.getSchedule12()
        }


      }
      else {
        if (office) {
          this.currentOffice = office

          this.filters2.office = office.id
          this.filters2.employee = office.employee

          if (this.scheduleViewType == 1) {
            this.getSchedule8()
          }
          else {
            this.getSchedule9()
          }
        }
        else {
          if (this.currentOffice) {
            this.filters2.office = this.currentOffice.id
            this.filters2.employee = this.currentOffice.employee

            if (this.scheduleViewType == 1) {
              this.getSchedule8()
            }
            else {
              this.getSchedule9()
            }
          }
        }
      }
    },

    getSchedule9() {

      if (this.includeRoom) {
        this.filters2.searchType = 5
      }
      else {
        this.filters2.searchType = 9
      }

      if (this.currentOffice && this.currentOffice.employee > -1) {
        this.filters2.employee = this.currentOffice.employee
      }

      this.getSchedule()
    },

    getSchedule6() {
      this.filters2.searchType = 6
      this.filters2.device = this.device.id
      this.getSchedule()
    },

    getSchedule11() {
      this.filters2.searchType = 11
      this.getSchedule()
    },
    getSchedule12() {
      this.filters2.searchType = 12
      this.getSchedule()
    },

    selectedClinic(item) {
      this.resetFilters()
      this.docFilter = ''
      this.officeFilter = ''
      this.scheduleForClinic(item)
    },

    selectedVehicle(item) {
      this.resetFilters()
      this.docFilter = ''
      this.officeFilter = ''
      this.vehicleSelected = item
      this.scheduleForVehicle(item)
    },

    selectedOffice(item) {
      this.resetFilters()
      this.docFilter = ''
      this.clinicFilter = ''
      this.roomFilter = ''
      this.currentOffice = item
      this.scheduleForOffice(item)
    },

    selectedRoom(item) {
      this.resetFilters()
      this.docFilter = ''
      this.clinicFilter = ''
      this.scheduleForRoom(item)
    },

    scheduleForVehicle(item) {
      if (item) {
        this.filters2.employee = -1
        this.filters2.spec = -1
        this.filters2.area = -1
        this.filters2.clinic = -1
        this.filters2.vehicle = item.id
        this.device = null
      }

      //this.getSchedule()
    },

    scheduleForClinic(item) {
      if (item) {
        this.filters2.employee = -1
        this.filters2.spec = -1
        this.filters2.area = -1
        this.filters2.clinic = item.id
        this.device = null
      }

      //this.getSchedule()
    },

    scheduleForOffice(item) {
      if (item) {
        if (item) this.filters2.office = item.id
        else this.filters2.office = null
        this.filters2.employee = item.employee
        this.filters2.room = null
      }
    },

    scheduleForRoom(item) {
      if (item) this.filters2.room = item.id
      else this.filters2.room = null
    },

    selectedEmployee(item) {
      this.resetFilters()
      if (item) {
        this.employee = item
        this.filters2.employee = item.id
        if (this.employee.specializations && this.employee.specializations.length > 0) {
          this.filters2.spec = this.employee.specializations[0]
        } else {
          this.filters2.spec = -1
        }
      }
      else this.employee = -1
    },

    selectedDevice(item) {
      if (item) this.device = item
      else this.device = null
    },

    openWorktimeScheduler() {
      this.isWorktimeSchedulerModalActive = true
    },

    getEmployeeSlots() {
      this.requestInProgress = true;
      if (this.me && this.me.id) {
        this.$store
          .dispatch(Action.SLOT_GET_BY_EMPLOYEE, this.me.id)
          .then((response) => {
            this.employeeSlots = response;
            this.requestInProgress = false;
          })
          .catch((error) => {
            this.snackbar("Nie pobrano slotów");
            this.apiProblem(error);
            this.requestInProgress = false;
          });
      }
    },

    resetEwus() {
      this.$store.commit(Mutation.EWUS_SET_EWUS, null)
      this.$store.commit(Mutation.EWUS_SET_EWUS_GUID, null);
      this.$store.commit(Mutation.EWUS_SET_EWUS_PENDING, true);

      let pay = {
        pesel: this.patient.pesel,
        workerId: this.me.id + "",
        domainId: this.me.domainId + "",
      };

      this.$store
        .dispatch(Action.EWUS_CHECK_PESEL, pay)
        .then((response) => {
          this.$store.commit(Mutation.EWUS_SET_EWUS_GUID, response);
        })
        .catch((error) => {
          this.apiProblem(error);
        });
    },
  }
}
</script>

<style scoped lang="scss">
.box-grow {
  flex: 1 1 auto; /* formerly flex: 1 0 auto; */
  background: green;
  padding: 5px;
  margin: 5px;
  overflow: auto; /* new */
}
</style>
