<template>
  <div>
    <div class="columns is-variable is-0 is-desktop">
      <div class="column is-narrow" v-show="hideFilters">
        <p class="mb-3">
          <b-tooltip position="is-right" label="Zakończ edycję" v-if="editorTrigger">
            <b-button
              size="is-small"
              icon-left="calendar-edit"
              type="is-secondary"
              @click="editorTrigger = false"
            ></b-button>
          </b-tooltip>
          <b-tooltip position="is-right" label="Konfiguruj strefy" v-else>
            <b-button
              size="is-small"
              :disabled="!schedule2editor"
              icon-left="calendar-edit"
              @click="editorTrigger = true"
            ></b-button>
          </b-tooltip>
        </p>
        <p class="mb-3">
          <b-tooltip position="is-right" label="Pokaż filtry">
            <b-button size="is-small" icon-left="eye" @click="hideFilters = false"></b-button>
          </b-tooltip>
        </p>
        <p>
          <b-tooltip position="is-right" label="Odśwież dane terminarza">
            <b-button
              :disabled="editorTrigger"
              size="is-small"
              icon-left="refresh"
              @click="getSchedule"
            ></b-button>
          </b-tooltip>
        </p>
      </div>

      <div
        v-if="focusBlock"
        class="card p-1 has-background-danger"
        style="position: fixed; bottom: 30px;right:30px;z-index: 29;"
      >
        <div class="card p-4">
          <b-field expanded>
            <p class="control is-expanded">
              <b-button icon-left="clock-outline" expanded>Przekładanie wizyty</b-button>
            </p>
            <p class="control">
              <b-button type="is-danger" icon-left="close" @click="cancelReschedule">Anuluj</b-button>
            </p>
          </b-field>
          <table class="table is-bordered w-100">
            <tr>
              <th>Pacjent</th>
              <th>{{focusBlock.name}}</th>
            </tr>
            <tr>
              <td>Typ</td>
              <td>{{focusBlock.typeName}}</td>
            </tr>
            <tr>
              <td>Godzina</td>
              <td>{{mTime(focusBlock.start)}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div
        v-if="focusArea"
        class="card p-1 has-background-danger"
        style="position: fixed; bottom: 30px;right:30px;z-index: 29;"
      >
        <div class="card p-4">
          <b-field expanded>
            <p class="control is-expanded">
              <b-button icon-left="clock-outline" expanded>Przekładanie strefy</b-button>
            </p>
            <p class="control">
              <b-button type="is-danger" icon-left="close" @click="cancelReschedule">Anuluj</b-button>
            </p>
          </b-field>
          <table class="table is-bordered w-100">
            <tr>
              <th>Typ</th>
              <th>{{focusArea.name}}</th>
            </tr>
          </table>
        </div>
      </div>

      <div
        v-show="!hideFilters"
        class="column pt-3 is-narrow"
        style="min-width: 19.1rem !important; max-width: 19.1rem !important"
      >
        <div v-if="patient">
          <ContentPanel icon="card-account-details-outline" title="Pacjent">
            <template slot="addon">
              <b-button @click="cancelPatient" size="is-small">Wyczyść</b-button>
            </template>
            <p class="title is-5 mb-3">{{patient.lastName}} {{patient.firstName}}</p>
            <p class="is-size-7 has-text-weight-semibold" style="margin-bottom: -5px;">PESEL:</p>
            <p>{{patient.pesel}}</p>
          </ContentPanel>
        </div>
        <b-field grouped>
          <!-- <b-button
            v-if="multiOffices.length > 1"
            icon-left="calendar-edit"
            size="is-small"
            disabled
            expanded
            style="z-index: 29;"
          >
            <b-tooltip
              label="Niedostępne przy wyborze wielu gabinetów"
              position="is-bottom"
            >Konfiguracja stref</b-tooltip>
          </b-button> -->
          <b-button
            @click="editorTrigger = false"
            icon-left="calendar-edit"
            size="is-small"
            expanded
            type="is-secondary"
            v-if="editorTrigger"
          >Zakończ konfigurację</b-button>
          <b-button
            v-else
            @click="editorTrigger = true"
            icon-left="calendar-edit"
            size="is-small"
            expanded
            :disabled="!schedule2editor"
          >Konfiguracja stref</b-button>
        </b-field>
        <b-field grouped>
          <b-button
            size="is-small"
            icon-left="eye-off"
            class="mr-1"
            @click="hideFilters = true"
          >Ukryj filtry</b-button>
          <b-field expanded class="mb-1">
            <p class="control">
              <b-button size="is-small" type="is-static">Tryb</b-button>
            </p>
            <b-select size="is-small" expanded v-model="scheduleViewType">
              <option :value="0">Wybrane dni</option>
              <option :value="1">Tydzień od</option>
              <option :value="2">Najbliższe terminy</option>
            </b-select>
          </b-field>
        </b-field>
        <b-field class="mb-0">
          <b-datepicker
            style="background-color: transparent"
            v-model="datesSelected"
            :first-day-of-week="1"
            placeholder="Wybierz"
            custom-class="hand"
            icon="calendar-month"
            :multiple="scheduleViewType === 0"
            :disabled="scheduleViewType === 2"
            ref="datesPicker"
            :mobile-native="false"
            :key="scheduleViewType"
            expanded
          >
            <b-field>
              <b-button
                size="is-small"
                type="is-primary"
                icon-left="calendar-month"
                class="mr-1"
                @click="cleanDates(true)"
              >Dzisiaj</b-button>
              <b-button
                size="is-small"
                type="is-danger"
                icon-left="close"
                class="mr-1"
                v-if="scheduleViewType === 0"
                @click="cleanDates(false)"
              >Wyczyść</b-button>

              <!-- <b-button
                size="is-small"
                expanded
                type="is-primary"
                @click="$refs.datesPicker.toggle()"
                icon-left="check"
              >Zamknij</b-button>-->
            </b-field>
          </b-datepicker>

          <p class="control">
            <b-button
              icon-left="refresh"
              @click="onMenuOfficeClick(null)"
              :type="multipleMode ? 'is-primary':''"
              :loading="requestInProgress"
              :disabled="editorTrigger"
            >
              <span v-if="editorTrigger">Tryb edycji</span>
              <span v-else-if="multipleMode">Pobierz</span>
              <span v-else>Odśwież</span>
            </b-button>
          </p>
        </b-field>
        <table>
          <tr>
            <td style="padding-bottom: 1px;">
              <b-switch
                v-model="multipleMode"
                :rounded="false"
                size="is-small"
                type="is-primary"
                class="mr-1"
              >Multiwybór</b-switch>
            </td>
            <td style="padding-top: 1px;">
              <span v-if="multipleMode && multiOffices.length > 0" class="has-text-secondary">
                |
                <a class="is-size-7 has-text-secondary" @click="multiOffices = []">
                  <u>Wyczyść zaznaczenie</u>
                </a>
              </span>
            </td>
          </tr>
        </table>

        <div v-if="multipleMode" class="pr-3">
          <b-field label="Wybierz placówkę" class="wide mt-2 mb-1" label-position="on-border">
            <b-autocomplete
              v-model="facilityName"
              :open-on-focus="true"
              :data="filteredFacilities"
              field="name"
              @select="selectFacility"
              clearable
              expanded
              size="is-small"              
            >
              <template slot-scope="props">
                <div style="line-height: 0.95rem;">
                  <p class="has-text-weight-semibold pb-1" style="font-size:0.8rem">{{ props.option.name }}</p>
                  <p v-if="props.option.address">
                    {{ props.option.address.street }}
                    {{ props.option.address.buildingNumber }}
                    {{ props.option.address.apartmentNumber ? "/ " : "" }}
                    {{ props.option.address.apartmentNumber }}
                    <br />
                    {{ props.option.address.zipCode }}
                    {{ props.option.address.city }}
                  </p>
                </div>
              </template>
            </b-autocomplete>
          </b-field>
          <p class="is-size-7 pb-1" v-if="selectedFacilityId">
            <a class="has-text-grey mr-3 ml-1" @click="multiselectFacility(true)">
              <u>Zaznacz wszystko</u>
            </a>
            <a
              class="has-text-grey is-pulled-right"
              @click="multiselectFacility(false)"
            >
              <u>Wyczyść</u>
            </a>
          </p>
          <b-collapse
            :open="true"
            v-for="clinic in generalMenu"
            :key="clinic.index"
            style="border-left: 3px solid #4697ca;"
          >
            <template #trigger="propsMain">
              <div class="pt-1 mt-3">
                <table>
                  <tr>
                    <td class="pr-2 pl-1">
                      <b-icon type="is-primary" :icon="propsMain.open ? 'folder-open' : 'folder'"></b-icon>
                    </td>
                    <td>
                      <div
                        class="is-uppercase has-text-weight-bold has-text-primary is-size-7"
                      >{{ mGetClinic(clinic.id) }}</div>
                    </td>
                  </tr>
                </table>
              </div>
            </template>

            <div class="mt-2 ml-1">
              <b-collapse :open="false" v-for="clinicType in clinic.types" :key="clinicType.index">
                <template #trigger="props">
                  <table>
                    <tr>
                      <td class="pr-2 pl-2">
                        <b-icon :icon="props.open ? 'folder-open-outline' : 'folder-outline'"></b-icon>
                      </td>
                      <td>
                        <div
                          class="is-uppercase has-text-grey has-text-weight-semibold is-size-7"
                        >{{ mGetOfficeType(clinicType.id) }}</div>
                      </td>
                    </tr>
                  </table>
                </template>
                <div class="pb-3">
                  <p class="is-size-7 pb-1" v-if="clinicType.offices && clinicType.offices.length > 1">
                    <a class="has-text-grey mr-3 ml-3" @click="multiselect(clinicType.offices, true)">
                      <u>Zaznacz wszystko</u>
                    </a>
                    <a
                      class="has-text-grey is-pulled-right"
                      @click="multiselect(clinicType.offices, false)"
                    >
                      <u>Wyczyść</u>
                    </a>
                  </p>
                  <div
                    v-for="office in clinicType.offices"
                    :key="office.index"
                    class="pt-1 pb-1"
                    :class="{'has-text-secondary':multiOffices.includes(office)}"
                  >
                    <div class="pl-5">
                      <b-checkbox
                        size="is-small"
                        class="has-text-weight-semibold"
                        type="is-secondary"
                        v-model="multiOffices"
                        :native-value="office"
                      >
                        <div>
                          {{ office.name }}
                          <span class="has-text-danger" v-if="office.isScheduleBlocked">
                            ZABLOKOWANY
                          </span>
                        </div>
                      </b-checkbox>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </b-collapse>
        </div>

        <b-menu v-else class="schedule-menu mt-3" :accordion="false">
          <b-menu-list>
            <b-menu-item v-for="clinic in generalMenu" :key="clinic.index" class="clinic-type-menu">
              <template slot="label">
                <div class="columns is-variable is-0">
                  <div class="column is-narrow">
                    <b-icon icon="home"></b-icon>
                  </div>
                  <div class="column">
                    <div
                      class="is-uppercase is-size-7"
                      style="padding-top: 5px"
                    >{{ mGetClinic(clinic.id) }}</div>
                  </div>
                </div>
              </template>
              <b-menu-item v-for="clinicType in clinic.types" :key="clinicType.index" class="office-type-menu">
                <template slot="label">
                  <div class="columns is-variable is-0">
                    <div class="column is-narrow">
                      <b-icon icon="clipboard-pulse"></b-icon>
                    </div>
                    <div class="column is-size-7">
                      <div style="padding-top: 5px">{{ mGetOfficeType(clinicType.id) }}</div>
                    </div>
                  </div>
                </template>
                <b-menu-item
                  v-for="office in clinicType.offices"
                  :key="office.index"
                  @click="onMenuOfficeClick(office)"
                  class="office-menu"
                >
                  <template slot="label">
                    <div class="columns is-variable is-0">
                      <div class="column is-narrow">
                        <b-icon icon="account-circle"></b-icon>
                      </div>
                      <div class="column is-size-7">
                        <div style="padding-top: 5px">
                          {{ office.name }}
                          <span class="has-text-danger" v-if="office.isScheduleBlocked">
                            ZABLOKOWANY
                          </span>
                        </div>
                      </div>
                    </div>
                  </template>
                </b-menu-item>
              </b-menu-item>
            </b-menu-item>
          </b-menu-list>
        </b-menu>
        <!-- <code>{{filters2.facilities}}</code> -->
        <!--<hr />
        <pre class="is-size-7 p-1" style="white-space: pre-wrap">{{datesSelected}}</pre>
        <hr />-->        
      </div>
      <div
        class="column pl-3"
        ref="scheduleColumn"
        :style="hideFilters ? 'max-width:calc(100% - 2.1rem);':'max-width:calc(100% - 19.2rem);'"
      >
        <Schedule
          :trigger="trigger"
          :resetTrigger="resetTrigger"
          :filters="filters2"
          :patient="patient"
          :editorActive="editorTrigger"
          :scheduleViewType="scheduleViewType"
          :rescheduleMode="focusBlock != null"
          @created="onAppointmentCreated"
          @response="requestInProgress = false"
          @error="requestInProgress = false"
          @search-patient="searchPatientEvent"
          @scroll-days="changeDays"
          @area-moved="areaMoved"
        ></Schedule>
      </div>
    </div>

    <b-modal
      :active.sync="isActiveModal"
      :destroy-on-hide="true"
      scroll="keep"
      has-modal-card
      :can-cancel="true"
    >
      <div class="modal-card" style="overflow: visible">
        <header class="modal-card-head has-background-kin">
          <p class="modal-card-title has-text-primelight" style="line-height: 2rem">Pacjent</p>
        </header>

        <section class="modal-card-body">
          <b-table
              v-if="patients && patients.length > 0 && !patient"
              :data="patients ? patients : []"
              narrowed
              hoverable
              detail-key="id"
              :loading="requestInProgress"
              class="mt-3 fs-09"
              style="max-width: 100%"
              :row-class="
                (row, index) => row.domainId != me.domainId && 'has-text-grey'
              "
            >
              <b-table-column
                width="1rem"
                label="Akcje"
                v-slot="props"
                cell-class="hand"
              >
                <b-button
                  :type="props.row.domainId == me.domainId ? 'is-primary' : ''"
                  :disabled="props.row.domainId !== me.domainId"
                  @click="selectPatient(props.row)"
                  icon-left="folder-move"
                  size="is-small"
                  >Wybierz</b-button
                >
              </b-table-column>
              <b-table-column
                field="lastName"
                label="Nazwisko"
                v-slot="props"
                >{{ props.row.lastName }}</b-table-column
              >
              <b-table-column field="firstName" label="Imię" v-slot="props">{{
                props.row.firstName
              }}</b-table-column>
              <b-table-column
                field="pesel"
                searchable
                label="PESEL lub nr dokumentu"
                v-slot="props"
              >
                <span v-if="props.row.pesel">{{ props.row.pesel }}</span>
                <span v-else>{{ props.row.identityDocumentNumber }}</span>
              </b-table-column>
              <b-table-column
                field="teleAddressTelephoneNumber"
                label="Inne"
                v-slot="props"
                cell-class="is-size-7"
              >
                <p>ur. {{ mDate(props.row.dateOfBirth) }}</p>
                <p>tel. {{ props.row.teleAddressTelephoneNumber }}</p>
                <p>
                  {{ props.row.addressStreet }}
                  <span
                    v-if="
                      props.row.addressApartmentNumber != null &&
                      props.row.addressApartmentNumber != ''
                    "
                    >/{{ props.row.addressApartmentNumber }}</span
                  >
                  {{ props.row.addressHouseNumber }}
                </p>
                <p>
                  {{ props.row.addressZipCode }}, {{ props.row.addressCity }}
                </p>
              </b-table-column>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon
                        icon="account-reactivate"
                        size="is-large"
                      ></b-icon>
                    </p>
                    <p>Brak wyników</p>
                  </div>
                </section>
              </template>
            </b-table>

          <table v-if="patient !== null" class="table is-narrow is-bordered" style="width: 100%">
            <tr>
              <th colspan="2">Dane pacjenta</th>
            </tr>
            <tr>
              <td>Imię i nazwisko:</td>
              <td class="has-text-right has-text-weight-medium">
                {{ patient.firstName.toUpperCase() }}
                {{ patient.lastName.toUpperCase() }}
              </td>
            </tr>
            <tr>
              <td>PESEL:</td>
              <td
                v-if="patient.pesel != null && patient.pesel != ''"
                class="has-text-right has-text-weight-medium"
              >{{ patient.pesel }}</td>
              <td v-else class="has-text-right has-text-weight-medium">-</td>
            </tr>
            <tr>
              <td>Data urodzenia:</td>
              <td class="has-text-right has-text-weight-medium">{{ mDate(patient.dateOfBirth) }}</td>
            </tr>
            <tr>
              <td>Adres zamieszkania:</td>
              <td class="has-text-right has-text-weight-medium">
                {{ patient.addressStreet }}
                {{ patient.addressHouseNumber }}
                <span
                  v-if="
                    patient.addressApartmentNumber != null &&
                    patient.addressApartmentNumber != ''
                  "
                >/{{ patient.addressApartmentNumber }}</span>
                , {{ patient.addressCity }}
              </td>
            </tr>
            <tr>
              <td>Dokument tożsamości:</td>
              <td class="has-text-right has-text-weight-medium">
                <span
                  v-if="
                    patient.identityDocumentNumber != null &&
                    patient.identityDocumentNumber != ''
                  "
                >{{ patient.identityDocumentNumber }}</span>
                <span v-else>-</span>
              </td>
            </tr>
          </table>

          <div v-if="!patient && searchCounter > 0 && !isLoading &&
                patients.length < 1 &&
                !requestInProgress &&
                !incorrectPesel">
            <p>Nie znaleziono danych pacjenta</p>
            <b-field class="mt-3 mb-2">
              <b-button @click="isAddPatientModalActive = true" type="is-primary" rounded expanded>
                <b-icon icon="account-plus" class="mr-2 mb-2 vam"></b-icon>Dodaj pacjenta
              </b-button>
            </b-field>
          </div>
        </section>
        <footer class="modal-card-foot buttons is-right">
          <b-button type="button" @click="cancelSearch">Anuluj</b-button>
          <b-button
            type="is-primary"
            :disabled="!patient"
            @click="isActiveModal = false; openVisitModalAdd()"
          >Potwierdź i umów wizytę</b-button>
        </footer>
      </div>
    </b-modal>
    <LabRegistrationAddPatientModal
      :isModalActive="isAddPatientModalActive"
      :pesel="peselPatient"
      @modal-closed="closeAddPatientModal"
    ></LabRegistrationAddPatientModal>
  </div>
</template>

<script>
import CommonMixins from "@/mixins/commons";
import PersonnelMixins from "@/mixins/personnel";
import AppoMixins from "@/mixins/appointments";
import ClinicsMixins from "@/mixins/clinics";
import SpecMixins from "@/mixins/specializations";
import MedEnums from "@/mixins/med_enums";
import DateHelpers from "@/mixins/date_helpers";
import moment from "moment";
import { Action } from "@/store/config_actions";
import Schedule from "@/components/schedule2/Schedule2";
import { Mutation } from "@/store/config_mutations";
import LabRegistrationAddPatientModal from "@/components/lab/lab-registration/LabRegistrationAddPatientModal";
// import ContentPanel from '@/components/tools/ContentPanel'
import { Cookies } from '@/store/config_cookies';

export default {
  name: "RegistrationSchedule2RIS",
  mixins: [
    CommonMixins,
    DateHelpers,
    MedEnums,
    SpecMixins,
    ClinicsMixins,
    PersonnelMixins,
    AppoMixins
  ],

  components: {
    Schedule,

    LabRegistrationAddPatientModal,
    //  ContentPanel
  },

  props: {
    resetTrigger: { type: Number, required: false },
  },

  watch: {
    focusArea(val) {
      if (val) {
        this.editorTrigger = true
      }
    },

    multipleMode(val) {
      this.$cookies.set(Cookies.SCHEDULE_MULTICHOICE_RIS, val);
      this.multiOffices = []
    },

    selectedCluster(value) {
      if (value) {
        var facs = [];

        // Filter facility IDs
        this.facilities.forEach((f) => {
          if (f.clusterId === value.internalId) {
            facs.push(f.internalId);
          }
        });

        this.filters2.facilities = facs;
      } else {
        // All clusters included
        this.filters2.facilities = null;
      }
    },

    resetTrigger() {
      this.scheduleMode = 0;
      this.resetFilters();
    },

    searchMode(val) {
      this.resetFilters();
      this.$nextTick(() => {
        switch (val) {
          case 0:
            if (this.$refs.auto0) this.$refs.auto0.focus();
            break;
          case 1:
            if (this.$refs.auto1) this.$refs.auto1.focus();
            break;
          case 2:
            if (this.$refs.auto2) this.$refs.auto2.focus();
            break;
        }
      });
    },

    activeFacility() {
      this.getSchedule();
    },

    scheduleViewType() {
      if (this.scheduleViewType === 0) {
        this.datesSelected = [];
      } else if (this.scheduleViewType === 1) {
        this.datesSelected = null;
      } else if (this.scheduleViewType === 2) {
        this.datesSelected = null;
      }
    },

    allRooms(val) {
      if (!val) {
        this.filters2.room = null;
      }
    },

    datesSelected() {
      this.refactorDates();
    },

    scheduleMode() {
      this.resetFilters();
    },

    allOffices: {
      deep: true,
      handler(valNew, valOld) {
        if ((valOld == null || (valOld != null && valOld.length == 0)) && valNew != null && valNew.length > 0) {
          this.wrapGeneralMenu();
        }
      }
    }
  },

  data: function () {
    return {
      searchMode: 0,
      generalMenu: [],
      scheduleMode: 0,
      selectedFacility:null,
      selectedFacilityId:null,
      facilityName:"",

      ///--------RIS params
      isAddPatientModalActive: false,
      peselPatient: "",
      patients:[],
      patientSearchType: 0,
      incorrectPesel:false,
      isLoading: false,
      isActiveModal: false,
      searchCounter: 0,
      ///--------------

      // filters: {
      //   dates: [],
      //   subjectId: -2,
      //   patientId: null,
      //   subject: null,
      //   spec: null,
      //   type: null,
      //   subtype: null,
      //   service: null,
      //   timeless: null,
      //   private: null
      // },

      // filters2: {
      //   "searchType": 0,
      //   "dates": [],
      //   "subjectId": null,
      //   "clinicId": null,
      //   "spec": null,
      //   "area": null
      // },

      filters2: {
        searchType: 0,
        dates: [],
        facilities: [],
        employee: -1,
        device: null,
        clinic: -1,
        spec: -1,
        area: -1,
        office: null,
        "multiOffice": []
      },

      selectedCluster: null,

      employee: null,
      requestInProgress: false,
      scheduleViewType: 1,
      datesSelected: new Date(),
      docFilter: "",
      clinicFilter: "",
      officeFilter: "",
      roomFilter: "",
      trigger: 0,
      clearTrigger: 0,
      medicalDevices: [],
      offices: [],
      rooms: [],
      deviceFilter: "",
      device: null,
      selectedArea: -1,
      columnWidth: 500,
      includeRoom: false,
      allRooms: false,
      hideFilters: false,
      currentOffice: null,
      multipleMode: true,
      editorTrigger: false,
      multiOffices: [],
    };
  },

  mounted() {
    let multiCookie = this.$cookies.get(Cookies.SCHEDULE_MULTICHOICE_RIS);

    if (multiCookie != undefined && multiCookie != null) {
      this.multipleMode = multiCookie == "true"
    }
    else {
      // do nothing
    }

    this.downloadMedicalDevices();
    // this.downloadOffices()
    this.downloadRooms();

    this.selectedCluster = null; // this.clusters.length > 0 ? this.clusters[0] : null

    this.datesSelected = new Date();

    let moments = [];
    let thisMoment = moment(this.datesSelected);
    moments.push(thisMoment);

    for (let index = 1; index < 7; index++) {
      const momentAdded = moment(thisMoment).add(index, "days");
      moments.push(momentAdded);
    }

    for (let index = 0; index < moments.length; index++) {
      const element = moments[index];

      if (element.format("ddd") !== "Sun") {
        let local = element.format("YYYY-MM-DD");
        this.filters2.dates.push(local);
      }
    }

    this.wrapGeneralMenu();
  },

  computed: {
    schedule2editor() {
      return this.$store.state.employee.permits.schedule2editor
    },
    focusArea() {
      return this.$store.state.poz.focusArea
    },
    selectedBlock() { return this.$store.state.schedule.currentBlock },
    configOffices() {
      return this.$store.state.config.offices;
    },
    clusters() {
      return this.$store.state.config.clusters;
    },
    facilities() {
      return this.$store.state.config.facilities ?? [];
    },
    allOffices() {
      return this.$store.state.offices.offices;
    },

    filteredOffices(){
      return this.allOffices?.filter(
        (office) =>
          office.type == 1010 || office.type == 1000 || office.type == 1020 || office.type == 1030
      ) ?? [];
    },

    availableFacilities(){
      let results = []
        this.filteredOffices.forEach(office => {
          let existing = results.find(x=> x.id == office.facilityId)
          if(existing){
            //ok we have faility
          }else{          
            let facility = this.facilities.find(x => x.id == office.facilityId)
            if(facility){
              results.push(facility);
            }
          }
        });
      return results;
    },

    filteredFacilities() {
      if (this.availableFacilities) {
        return this.availableFacilities.filter((option) => {
          let result = false;
          if (option.name) {
            result = option.name
              .toString()
              .toLowerCase()
              .indexOf(this.facilityName.toLowerCase()) >= 0
          }
          if (!result && option.address) {
            if (option.address.street) {
              result = option.address.street
                .toString()
                .toLowerCase()
                .indexOf(this.facilityName.toLowerCase()) >= 0
            }

            if (!result && option.address.city) {
              result = option.address.city
                .toString()
                .toLowerCase()
                .indexOf(this.facilityName.toLowerCase()) >= 0
            }
          }
          if (!result && option.name) {
            result = option.name
              .toString()
              .toLowerCase()
              .indexOf(this.facilityName.toLowerCase()) >= 0
          }

          return (result)
        });
      }
      return [];
    },

    activeFacility() {
      return this.$store.state.clinic.activeClinic;
    },
    patient() {
      return this.$store.state.registration.patient;
    },
    docs() {
      return this.$store.state.employee.all;
    },

    me() {
      return this.$store.state.employee.me
    },

    elmpoyeeFiltered() {
      return this.docs.filter((option) => {
        let id = (option.lastName + option.firstName).toLowerCase();
        if (this.docFilter != null) {
          return id.indexOf(this.docFilter.toLowerCase()) >= 0;
        } else return false;
      });
    },

    clinicFiltered() {
      return this.mClinics.filter((option) => {
        let id = option.name.toLowerCase();
        return id.indexOf(this.clinicFilter.toLowerCase()) >= 0;
      });
    },

    officesFiltered() {
      return this.allOffices.filter((option) => {
        let id = option.name.toLowerCase();
        return id.indexOf(this.officeFilter.toLowerCase()) >= 0;
      });
    },

    roomsFiltered() {
      return this.roomsOptions.filter((option) => {
        let id = option.name.toLowerCase();
        return id.indexOf(this.roomFilter.toLowerCase()) >= 0;
      });
    },

    roomsOptions() {
      if (this.filters2.office) {
        let array = this.rooms;

        if (this.currentOffice && this.currentOffice.facilityId) {
          if (!this.allRooms) {
            array = this.rooms.filter(
              (r) => r.facilityId === this.currentOffice.facilityId
            );
          }

          return array;
        } else return array;
      } else return [];
    },

    devicesFiltered() {
      return this.medicalDevices.filter((option) => {
        let id = option.name.toLowerCase();
        return id.indexOf(this.deviceFilter.toLowerCase()) >= 0;
      });
    },

    focusAppointment() {
      return this.$store.state.poz.focusAppointment;
    },
    focusBlock() {
      return this.$store.state.poz.focusBlock;
    },
  },

  methods: {
    areaMoved() {
      this.editorTrigger = false
    },

    selectFacility(option) {
      this.selectedFacility = option ? option.name : null;
      this.selectedFacilityId = option ? option.id : null;
      this.multiOffices = [];
      this.wrapGeneralMenu();
    },

    searchPatientEvent(payload) {
      //this.$emit('search-patient', payload)
      this.peselPatient = payload;
      this.patientSearch2();
    },

    changeDays(val) {
      if (this.scheduleViewType != 0) {
        var result = this.datesSelected.setDate(this.datesSelected.getDate() + val);
        this.datesSelected = new Date(result);

        if (moment(this.datesSelected).format('ddd') === 'Sun') {
          let skipSunday = val > 0 ? 1 : -1;
          result = this.datesSelected.setDate(this.datesSelected.getDate() + skipSunday);
        }
        this.datesSelected = new Date(result);

        this.trigger++
      } else {
        //do nothing, change on calendar :P
      }
    },

    openVisitModalAdd() {
      this.$store.commit(Mutation.SCHEDULE_SET_ONECLICK_ADD_MODAL, true);
    },

    momentDate(item) {
      return moment(item);
    },

    cancelReschedule() {
      this.$store.commit(Mutation.APPOINTMENT_SIMPLE_FOCUS, null);
    },

    multiselect(offices, selectMode) {

        if (offices && this.multiOffices) {
          if (selectMode) {
            offices.forEach(o => {
              if (this.multiOffices.includes(o)) {
                // ok
              }
              else {
                this.multiOffices.push(o)
              }
            })
          }
          else {

            offices.forEach(o => {

              if (this.multiOffices.includes(o)) {
                let ind = this.multiOffices.indexOf(o)

                if (ind > -1) {
                  this.multiOffices.splice(ind, 1)
                }
              }
            })
          }
        }
      },

      multiselectFacility(selectMode) {

        let offices = this.filteredOffices.filter(x=>x.facilityId == this.selectedFacilityId)

        if (offices && this.multiOffices) {
          if (selectMode) {
            offices.forEach(o => {
              if (this.multiOffices.includes(o)) {
                // ok
              }
              else {
                this.multiOffices.push(o)
              }
            })
          }
          else {

            offices.forEach(o => {

              if (this.multiOffices.includes(o)) {
                let ind = this.multiOffices.indexOf(o)

                if (ind > -1) {
                  this.multiOffices.splice(ind, 1)
                }
              }
            })
          }
        }
      },

    wrapGeneralMenu() {
      let clins = [];

      // let filteredOffice = this.allOffices.filter(
      //   (office) =>
      //     office.type == 1010 || office.type == 1000 || office.type == 1020 || office.type == 1030
      // );

      this.filteredOffices.forEach((office) => {
        let facilityFilter = true;

        if(this.selectedFacilityId){
         facilityFilter = office.facilityId == this.selectedFacilityId
        }

        if (office.clinicId > -1 && facilityFilter) {
          let existing = clins.find((c) => c.id === office.clinicId);

          if (existing) {
            // cool
          } else {
            existing = { id: office.clinicId, types: [] };
            clins.push(existing);
          }

          let extype = existing.types.find((e) => e.id === office.type);

          if (extype) {
            extype.offices.push(office);
          } else {
            extype = { id: office.type, offices: [] };
            extype.offices.push(office);
            existing.types.push(extype);
          }
        }
      });

      this.generalMenu = clins;
    },

    resetFilters() {
      this.filters2 = {
        searchType: 0,
        dates: this.filters2.dates,
        facilities: this.filters2.facilities,
        employee: -1,
        device: null,
        clinic: -1,
        spec: -1,
        area: -1,
        office: null,
        "multiOffice": []
      };

      this.clinicFilter = "";
      this.officeFilter = "";
      this.docFilter = "";
    },

    downloadOffices() {
      this.loading = true;
      this.$store
        .dispatch(Action.OFFICE_GET_ALL)
        .then((data) => {
          this.offices = data;
          this.loading = false;
        })
        .catch((error) => {
          this.apiProblem(error);
          this.loading = false;
        });
    },

    downloadRooms() {
      this.$store
        .dispatch(Action.OFFICEROOM_GET_ALL)
        .then((response) => {
          this.rooms = response;
        })
        .catch((error) => {
          this.apiProblem(error);
        });
    },

    downloadMedicalDevices() {
      this.requestInProgress = true;
      this.$store
        .dispatch(Action.MEDICAL_DEVICE_GET_ALL)
        .then((data) => {
          this.medicalDevices = data;
          this.requestInProgress = false;
        })
        .catch((error) => {
          this.apiProblem(error);
          this.requestInProgress = false;
        });
    },

    getItem(id) {
      let category = this.configOffices.find((c) => c.id == id);
      if (category) return category.name;
      else return `???`;
    },

    cleanDates(resetToToday) {
      while (this.filters2.dates.length > 0) this.filters2.dates.pop();

      if (resetToToday) {
        this.scheduleViewType = 1;
        this.$nextTick(() => {
          this.datesSelected = new Date();
        });
      } else {
        this.scheduleViewType++;
        this.$nextTick(() => {
          this.scheduleViewType--;
        });
      }
    },

    refactorDates() {
      this.filters2.dates = [];

      if (this.datesSelected) {
        switch (this.scheduleViewType) {
          case 0: {
            this.datesSelected.forEach((d) => {
              this.filters2.dates.push(moment(d).format("YYYY-MM-DD"));
            });
            break;
          }
          case 1: {
            let moments = [];
            let thisMoment = moment(this.datesSelected);
            moments.push(thisMoment);

            for (let index = 1; index < 7; index++) {
              const momentAdded = moment(thisMoment).add(index, "days");
              moments.push(momentAdded);
            }

            for (let index = 0; index < moments.length; index++) {
              const element = moments[index];

              if (element.format("ddd") !== "Sun") {
                let local = element.format("YYYY-MM-DD");
                this.filters2.dates.push(local);
              }
            }
            break;
          }
        }
      }
    },

    onAppointmentCreated(payload) {
      this.$emit("created", payload);
      this.$store.commit(Mutation.SCHEDULE_SET_ONECLICK_ADD_MODAL, false);
      this.$store.commit(Mutation.SCHEDULE_SET_CURRENT_BLOCK, null);
      this.patientSearchReset();
      this.$store.commit(Mutation.RIS_TRIGGER_ADD_UP);
    },

    getSchedule() {
      if (this.$refs.scheduleColumn) {
        this.columnWidth = this.$refs.scheduleColumn.clientWidth;
      }

      this.requestInProgress = true;
      this.trigger++;
    },

    getSchedule0() {
      this.filters2.searchType = 0;
      this.getSchedule();
    },

    getSchedule1() {
      this.filters2.searchType = 1;
      this.filters2.area = this.selectedArea;
      this.getSchedule();
    },

    getSchedule2() {
      this.filters2.searchType = 2;
      this.getSchedule();
    },

    getSchedule3() {
      this.filters2.searchType = 3;
      this.getSchedule();
    },

    getSchedule8() {
      if (this.includeRoom) {
        this.filters2.searchType = 4;
      } else {
        this.filters2.searchType = 8;
      }

      this.filters2.employee = -1
      if (this.currentOffice && this.currentOffice.employee > -1) {
        this.filters2.employee = -1// this.currentOffice.employee;
      }

      this.getSchedule();
    },

    onMenuOfficeClick(office) {
      if (this.multipleMode) {

        this.filters2.multiOffice = []
        this.multiOffices.forEach(x => this.filters2.multiOffice.push(x.id))

        if (this.scheduleViewType < 2) {
          this.getSchedule11()
        }
        else {
          this.getSchedule12()
        }
      }
      else {
        if (office) {
          this.currentOffice = office;

          this.filters2.office = office.id;
          this.filters2.employee = office.employee;

          if (this.scheduleViewType < 2) {
            this.getSchedule8();
          } else {
            this.getSchedule9();
          }
        } else {
          if (this.currentOffice) {
            this.filters2.office = this.currentOffice.id;
            this.filters2.employee = this.currentOffice.employee;

            if (this.scheduleViewType < 2) {
              this.getSchedule8();
            } else {
              this.getSchedule9();
            }
          }
        }
      }
    },

    getSchedule11() {
      this.filters2.searchType = 11
      this.getSchedule()
    },
    getSchedule12() {
      this.filters2.searchType = 12
      this.getSchedule()
    },

    getSchedule9() {
      if (this.includeRoom) {
        this.filters2.searchType = 5;
      } else {
        this.filters2.searchType = 9;
      }

      this.filters2.employee = -1
      if (this.currentOffice && this.currentOffice.employee > -1) {
        this.filters2.employee = -1// this.currentOffice.employee;
      }

      this.getSchedule();
    },

    getSchedule6() {
      this.filters2.searchType = 6;
      this.filters2.device = this.device.id;
      this.getSchedule();
    },

    selectedClinic(item) {
      this.resetFilters();
      this.docFilter = "";
      this.officeFilter = "";
      this.scheduleForClinic(item);
    },

    selectedOffice(item) {
      this.resetFilters();
      this.docFilter = "";
      this.clinicFilter = "";
      this.roomFilter = "";
      this.currentOffice = item;
      this.scheduleForOffice(item);
    },

    selectedRoom(item) {
      this.resetFilters();
      this.docFilter = "";
      this.clinicFilter = "";
      this.scheduleForRoom(item);
    },

    scheduleForClinic(item) {
      if (item) {
        this.filters2.employee = -1;
        this.filters2.spec = -1;
        this.filters2.area = -1;
        this.filters2.clinic = item.id;
        this.device = null;
      }

      //this.getSchedule()
    },

    scheduleForOffice(item) {
      if (item) this.filters2.office = item.id;
      else this.filters2.office = null;
      this.filters2.employee = item.employee;

      this.filters2.room = null;
    },

    scheduleForRoom(item) {
      if (item) this.filters2.room = item.id;
      else this.filters2.room = null;
    },

    selectedEmployee(item) {
      this.resetFilters();
      if (item) {
        this.employee = item;
        this.filters2.employee = item.id;
        this.filters2.spec = -1;
      } else this.employee = -1;
    },

    selectedDevice(item) {
      if (item) this.device = item;
      else this.device = null;
    },

    patientSearch() {
      this.isLoading = true;
      this.$store.commit(Mutation.REGISTRATION_RESET);
      this.$store
        .dispatch(Action.PATIENT_GET_BY_PESEL, this.peselPatient)
        .then((response) => {
          if (response.data) {
            this.$store.commit(
              Mutation.REGISTRATION_SET_PATIENT,
              response.data
            );
            this.searchCounter++;
          }
          this.isActiveModal = true;
          this.isLoading = false;
        })
        .catch((error) => {
          this.apiProblem(error);
          this.searchCounter++;
          this.isAddPatientButtonActive = true;
          this.isActiveModal = true;
          this.isLoading = false;
        });
    },

    patientSearch2() {
      this.patients = []
      this.isLoading = true
      this.peselPatient = this.peselPatient.replace("@", "")
      this.$store.commit(Mutation.REGISTRATION_RESET)
      /////////////////////////////////////////////////////////////////////////////////////////////////
      this.incorrectPesel = true

      let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]
      let sum = 0
      let controlNumber = parseInt(this.peselPatient.substring(10, 11))

      for (let i = 0; i < weight.length; i++) {
        sum += parseInt(this.peselPatient.substring(i, i + 1)) * weight[i]
      }
      sum = sum % 10

      if ((10 - sum) % 10 === controlNumber) {
        this.incorrectPesel = false
      }

      if (!this.incorrectPesel) {
        this.$store
          .dispatch(Action.PATIENT_SEARCH, {
            type: this.patientSearchType,
            pharse: this.peselPatient,
          })
          .then((response) => {
            this.patients = response.filter(
              (regPatient) => regPatient.domainId == this.me.domainId
            )

            if (this.patients.length > 0) {
              this.searchCounter++
              this.patientDocument = ""

              if (this.patients.length > 1) {
                this.successSnackbar(
                  "Znaleziono pacjentów: " + this.patients.length
                )
              } else {
                this.successSnackbar("Znaleziono pacjenta")
                this.$store.commit(
                  Mutation.REGISTRATION_SET_PATIENT,
                  this.patients[0]
                )
              }
            } else {
              this.searchCounter++
            }
            
            this.isActiveModal = true;
            this.isLoading = false
          })
          .catch((error) => {
            this.isLoading = false
            this.searchCounter++;
            this.isActiveModal = true;
            this.searchCounter++
            this.apiProblem(error)
          })
      } else {
        this.isLoading = false
        this.dangerSnackbar("Nieprawidłowy numer pesel")
      }

      //////////////////////////////////////////////////////////////////////////////////////
    },

    selectPatient(regPatient) {
      this.$store.commit(Mutation.REGISTRATION_SET_PATIENT, regPatient)
      this.successSnackbar("Wybrano pacjenta")
    },

    patientSearchReset() {
      this.$store.commit(Mutation.REGISTRATION_RESET);
    },

    cancelPatient() {
      this.$store.commit(Mutation.SCHEDULE_SET_ONECLICK_ADD_MODAL, false);
      this.$store.commit(Mutation.SCHEDULE_SET_CURRENT_BLOCK, null);
      this.patientSearchReset();
    },

    closeAddPatientModal() {
      this.isAddPatientModalActive = false;
    },

    cancelSearch() {
      this.isActiveModal = false;
      this.$store.commit(Mutation.SCHEDULE_SET_ONECLICK_ADD_MODAL, false);
      this.$store.commit(Mutation.SCHEDULE_SET_CURRENT_BLOCK, null);
      this.patientSearchReset();
    },
  },
};
</script>

<style scoped lang="scss">
.box-grow {
  flex: 1 1 auto; /* formerly flex: 1 0 auto; */
  background: green;
  padding: 5px;
  margin: 5px;
  overflow: auto; /* new */
}
</style>
