<template>
  <div class="modal-card" style="overflow: visible;width:100% !important">
    <section class="modal-card-body" v-if="block" style="min-height: 18rem;">
      <div class="columns">
        <div class="column is-6">
          <table class="table is-bordered w-100">
            <tr>
              <th>Data</th>
              <th>{{mDate(block.worktimeArea.start)}}</th>
            </tr>
            <tr>
              <th>Godzina</th>
              <th>{{mTime(block.worktimeArea.start)}} - {{mTime(block.worktimeArea.stop)}}</th>
            </tr>
            <tr>
              <th>Strefa</th>
              <th>
                {{getWorkAreaName(block.worktimeArea.area)}}
                <b
                  v-if="block.worktimeArea.isCito"
                  class="has-text-danger"
                >CITO</b>
              </th>
            </tr>
            <tr v-if="block.worktimeArea.officeId === null">
              <td class="has-text-weight-semibold">Poradnia</td>
              <td>{{ mGetClinic(block.worktimeArea.clinic) }}</td>
            </tr>
            <tr v-else>
              <td class="has-text-weight-semibold">Gabinet</td>
              <td>{{ getOffice(block.worktimeArea.officeId) }}</td>
            </tr>
            <tr>
              <td class="has-text-weight-semibold">Pracownik</td>
              <td>
                <span v-if="employee">{{employee.lastName}} {{employee.firstName}}</span>
                <b-skeleton size="is-small" v-else></b-skeleton>
              </td>
            </tr>
            <tr v-if="block.worktimeArea.office < 0">
              <td class="has-text-weight-semibold">Specjalizacja</td>
              <td>{{ mGetSpecialization(block.worktimeArea.spec) }}</td>
            </tr>
            <tr v-if="block.worktimeArea.officeRoomId">
              <td class="has-text-weight-semibold">Pokój</td>
              <td>{{ getRoom(block.worktimeArea.officeRoomId) }}</td>
            </tr>
            <tr>
              <td class="has-text-weight-semibold" style="width: 9rem !important;">Placówka</td>
              <td>{{ getClinic(block.worktimeArea.facility) }}</td>
            </tr>
            <tr>
              <td class="has-text-weight-semibold">Długość strefy</td>
              <td>{{block.fullAreaLength}} minut, w tym {{block.fullTimeTaken}} zajęte</td>
            </tr>
            <tr>
              <td class="has-text-weight-semibold">Pozostało</td>
              <td>{{block.fullTimeLeft}} minut w całej strefie, {{block.timeLeft}} minut w bieżącym bloku</td>
            </tr>
            <tr>
              <td class="has-text-weight-semibold">Utworzona</td>
              <td>
                {{mDateTime(block.worktimeArea.created)}} przez
                <span
                  v-if="createdBy"
                >{{createdBy.lastName}} {{createdBy.firstName}}</span>
                <b-skeleton size="is-small" v-else></b-skeleton>
              </td>
            </tr>
            <tr>
              <td class="has-text-weight-semibold">Opis</td>
              <td>{{block.worktimeArea.info}}</td>
            </tr>
          </table>
        </div>
        <div class="column">
          <table class="table is-bordered w-100">
            <tr>
              <th colspan="3">Wizyty</th>
            </tr>
            <tr>
              <th>Godzina</th>
              <th>Pacjent</th>
              <th>Typ</th>
            </tr>
            <tr v-for="item in selectedWorktimeAreaApps" :key="item.index">
              <td>{{mTime(item.start)}}</td>
              <td>{{item.name}}</td>
              <td>{{item.typeName}}</td>
            </tr>
          </table>
          <b-button
            type="is-danger"
            icon-left="alert"
            :disabled="!schedule2editor || (day.office && day.office.isScheduleBlocked)"
            size="is-small"
            outlined
            @click="deleteWorktime"
          >
            Usuń tę strefę
            <span v-if="!schedule2editor">(brak uprawnień)</span>
          </b-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CommonMixins from '@/mixins/commons'
import InvMixins from '@/mixins/inventory'
import ClinicsMixins from '@/mixins/clinics'
import PersonnelMixins from '@/mixins/personnel'
import DateHelpers from '@/mixins/date_helpers'
import ColorHelpers from '@/mixins/schedule_colors'
import AppoHelpers from '@/mixins/appointments'
import { Mutation } from '../../store/config_mutations'
import moment from 'moment'
import { Action } from '../../store/config_actions'

export default {
  name: 'Schedule2AreaDetails',
  mixins: [CommonMixins, DateHelpers, ColorHelpers, AppoHelpers, ClinicsMixins, PersonnelMixins, InvMixins],

  components: {

  },

  props: {
    day: { type: Object, required: false },
    date: { type: Object, required: true },
    block: { type: Object, required: true },
    isBackground: { type: Boolean, required: false },
    patient: { type: Object, required: false },
    timeOnlyMode: { type: Boolean, required: false },
    rescheduleMode: { type: Boolean, required: false }
  },

  watch: {
    block() {
      this.getColors()
    }
  },

  data: function () {
    return {
      colors: {
        dark: 'grey',
        light: 'silver',
      },
      isModalActive: false,
      overlayStyle: '',
      detalisModalActive: false,
      timelessOnly: false,
      employee: null,
      createdBy: null,
      selectedWorktimeAreaApps: []
    }
  },

  mounted() {
    this.selectedWorktimeAreaApps = []

    this.getColors()
    this.getEmployee()
    this.getEmployeeCreated()


    this.selectedWorktimeAreaApps = []

    if (this.block && this.block.worktimeArea && this.day && this.day.blocks) {
      this.selectedWorktimeAreaApps = this.day.blocks.filter(x => x.appointment && x.appointment.areaId == this.block.worktimeArea.id)
    }

    this.block.fullAreaLength = 0
    this.block.fullTimeLeft = 0
    this.block.fullTimeTaken = 0

    if (this.block.worktimeArea) {
      this.block.fullAreaLength = moment(this.block.worktimeArea.stop).diff(moment(this.block.worktimeArea.start), 'minutes')

      this.selectedWorktimeAreaApps.forEach(element => {
        this.block.fullTimeTaken += moment(element.end).diff(moment(element.start), 'minutes')
      });

      this.block.fullTimeLeft = this.block.fullAreaLength - this.block.fullTimeTaken
    }
  },

  computed: {
    schedule2editor() {
      return this.$store.state.employee.permits.schedule2editor
    },
    clinics() { return this.$store.state.config.facilities },
    allOffices() { return this.$store.state.offices.offices },
    configAreas() { return this.$store.state.config.areas },
    allRooms() { return this.$store.state.inventory.rooms },
    openAddModal() { return this.$store.state.schedule.openOneclickAddModal },
    selectedBlock() { return this.$store.state.schedule.currentBlock },
    isHistory() {
      let now = moment().startOf('day')
      return moment(this.block.worktimeArea.start).isBefore(now)
    },

    me() {
      return this.$store.state.employee.me
    },

    domainConflict() {
      if (this.patient) {
        return this.patient.domainId != this.me.domainId
      }
      else return false
    }
  },

  methods: {

    getEmployee() {
      if (this.block.worktimeArea.employee < 1) {
        this.employee = { firstName: "", lastName: "Brak" };
      } else {



        this.$store
          .dispatch(Action.EMPLOYEE_GET, this.block.worktimeArea.employee)
          .then((x) => {
            this.employee = x.data;
          })
          .catch((error) => {
            this.employee = { firstName: "", lastName: "Brak informacji o pracowniku ID: " + this.block.worktimeArea.employee };
            this.apiProblem(error);
          });
      }

      // this.$store.dispatch(Action.PATIENT_GET_BY_ID, this.block.appointment.patientId)
      //   .then((x) => {
      //     this.patient = x.data
      //   })
      //   .catch((error) => {
      //     this.apiProblem(error)
      //   })
    },


    getEmployeeCreated() {
      if (this.block.worktimeArea.createdBy < 1) {
        this.createdBy = { firstName: "", lastName: "Brak" };
      } else {
        this.$store
          .dispatch(Action.EMPLOYEE_GET, this.block.worktimeArea.createdBy)
          .then((x) => {
            this.createdBy = x.data;
          })
          .catch((error) => {
            this.createdBy = { firstName: "", lastName: "Błąd pobierania informacji" };
            this.apiProblem(error);
          });
      }

      // this.$store.dispatch(Action.PATIENT_GET_BY_ID, this.block.appointment.patientId)
      //   .then((x) => {
      //     this.patient = x.data
      //   })
      //   .catch((error) => {
      //     this.apiProblem(error)
      //   })
    },

    showInfo(item) {
      this.$buefy.snackbar.open({
        message: item,
        position: 'is-top',
        queue: false,
        pauseOnHover: true,
        type: 'is-secondary',
      })
    },

    showAreaDetails(e) {
      this.detalisModalActive = true
      e.preventDefault();
    },

    closeModal() {
      this.$store.commit(Mutation.SCHEDULE_SET_CURRENT_BLOCK, null);
    },

    emitTime(val) {
      this.$emit("time-selected", val)
      this.isModalActive = false
    },

    getWorkAreaName(id) {
      let category = this.configAreas.find(c => c.id == id)
      if (category) return category.name
      else return `Nieznana strefa`
    },

    getRoom(id) {
      let category = this.allRooms.find(c => c.id == id)
      if (category) return category.name
      else return `Nieznany pokój`
    },

    getOffice(id) {
      let category = this.allOffices.find(c => c.id == id)
      if (category) return category.name
      else return `${id}`
    },

    getClinic(id) {
      let clin = this.clinics.find(c => c.internalId == id)
      if (clin) return clin.name
      else return 'Nieznana placówka'
    },

    deleteWorktime() {
      if (this.block && this.block.worktimeArea) {
        let item = this.block.worktimeArea
        this.$buefy.dialog.confirm({
          message: `Czy na pewno chcesz usunąć wybraną strefę terminarza:<br /><b>${this.mDate(item.start)} ${this.mTime(item.start)} - ${this.mTime(item.stop)}</b><br />${this.block.name}?`,
          type: 'is-danger',
          scroll: 'keep',
          hasIcon: true,
          confirmText: 'Tak, usuń tę strefę',
          cancelText: 'Anuluj',
          onConfirm: () => {
            this.loading = true
            this.$store.dispatch(Action.S2_DELETE_WORKTIME, item.id)
              .then(() => {
                this.loading = false
                this.successSnackbar('Usunięto strefę')
                this.$emit('updated')

                //ADD IKZ - USUNIĘCIE STREFY
                //this.addIkzDeleteWorktime()
              })
              .catch((error) => {
                this.loading = false
                this.apiProblem(error)
              })
          }
        })
      }
    },

    emitUpdate() {
      this.isModalActive = false
      this.$emit('update')
    },

    emitCreate(payload) {
      this.isModalActive = false
      this.$emit('created', payload)
    },

    getColorsOLD() {
      let colors = this.mWorktimeColors.find(s => s.id === this.block.worktimeArea.area)

      if (colors === null || colors === undefined) {
        colors = { dark: '#68c5d7', light: '#dbeeef', text: '#fff' }
      }

      if (colors) {
        this.colors = colors
      }
    },

    getColors() {
      let colors = { dark: '#68c5d7', light: '#dbeeef', text: '#000' }

      if (this.configAreas) {
        let config = this.configAreas.find(s => s.id === this.block.worktimeArea.area)

        if (config) {
          colors.dark = config.darkColor ?? colors.dark
          colors.light = config.lightColor ?? colors.light
          colors.text = config.textColor ?? colors.text
        }
      }

      if (colors) {
        this.colors = colors
      }
    },

    getNoteStyle() {
      // return `color: ${this.colors.dark};`
      return `color: black;`
    },

    hoverOn() {
      if (this.isBackground) {
        // do nothing
      }
      else {
        this.overlayStyle = `background-color: rgba(255, 255, 255, 0.3) !important;`
      }
    },

    hoverOff() {
      this.overlayStyle = ''
    },
  }
}
</script>

<style scoped lang="scss">
.overlay {
  height: 100% !important;
  padding: 0 0 0 0.1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.cursor-cell {
  cursor: cell;
}

.block-base {
  height: 100% !important;
}

.small {
  font-size: 0.75rem;
  line-height: 0.8rem;
}

.smaller {
  font-size: 0.68rem;
  line-height: 0.58rem;
}

.hoverlight:hover {
  color: teal;
}

.rightrotate {
  writing-mode: tb-rl;
  text-orientation: sideways-right !important;
  position: absolute;
  right: -0.2rem;
  top: 0rem;
  max-width: 1rem !important;
  overflow: hidden;
  z-index: 10;
}
</style>
